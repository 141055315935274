// Import node_modules
import React from 'react';
import styled, { createGlobalStyle } from "styled-components";

// Import components
import { Search } from './Search.jsx';
import { commandsList } from './CommandsList.js';
import { CommandsContent } from './Commands.jsx';
import { Loading } from '../Loading.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

const GlobalStyle = createGlobalStyle`
    code {
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.primary};
        padding: 1px 3px;
    }
`;

export class Commands extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            commands: commandsList 
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }

    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            );
        }else{
            return (
                <Container>
                    <GlobalStyle />

                    <Content>
                        <Title>Commands</Title>
                    </Content>
                    
                    <Content>
                        <Search parentObject={this} />
                    </Content>

                    <Content>
                        <CommandsContent commands={this.state.commands} parentObject={this} />
                    </Content>

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            );
        }
    }
};

const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 12%;
    width: 76%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }

`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
`;

const AdContent = styled.div`
    max-width: 1920px;
    margin: 10px 12%;
    width: 76%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;
