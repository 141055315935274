// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import images
import Bullet from './../../Assets/Icons/Bullet.png';

export class Policy extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

            return (
                <Container>
                    <PolicyContainer>
                        <PolicyTitle>Disclaimer</PolicyTitle>
                        <PolicySubTitle>By donating to the bot you are agreeing that you understand:</PolicySubTitle>
                        <PolicyBulletContainer>
                            <PolicyBulletBlock>
                                <PolicyBullet src={Bullet} alt="" />
                                <PolicyBulletText>All payments are currently one-time payments to receive the listed perks.</PolicyBulletText>
                            </PolicyBulletBlock>

                            <PolicyBulletBlock>
                                <PolicyBullet src={Bullet} alt="" />
                                <PolicyBulletText>Some of the listed commands are only available for owners of a server. Please look through <PolicyLink href="/commands">commands page</PolicyLink> to find a list of the commands.</PolicyBulletText>
                            </PolicyBulletBlock>

                            <PolicyBulletBlock>
                                <PolicyBullet src={Bullet} alt="" />
                                <PolicyBulletText>For any issues with purchases or refunds, please contact us via our <PolicyLink href="https://discord.gg/kqefESMzQj" target="_blank">support server</PolicyLink> or email us at <PolicyLink href="mailto:help@amaribot.com">help@amaribot.com</PolicyLink></PolicyBulletText>
                            </PolicyBulletBlock>
                        </PolicyBulletContainer>
                    </PolicyContainer>

                    <RefundContainer>
                        <PolicyTitle>FAQ</PolicyTitle>
                        <PolicySubTitle>Refunds</PolicySubTitle>
                        <PolicyText>
                            Refunds are issued in very rare cases. If you threaten to chargeback, we reserve the right to ban/blacklist you from the bot.
                        </PolicyText>
                        <PolicyText>
                            For refunds please join our <PolicyLink href="https://discord.gg/kqefESMzQj" target="_blank">support server</PolicyLink> or email us at <PolicyLink href="mailto:help@amaribot.com">help@amaribot.com</PolicyLink>
                        </PolicyText>
                        <RefundSubTitle>One Time Payments</RefundSubTitle>
                        <PolicyText>
                            All payments are currently one time payments.
                        </PolicyText>
                        <RefundSubTitle>How do I get the perks?</RefundSubTitle>
                        <PolicyText>
                            Once you have made the payment, you will receive the perks automatically within 10-15 minutes. If you do not receive the perks within an hour join our <PolicyLink href="https://discord.gg/kqefESMzQj" target="_blank">support server</PolicyLink> for help.
                        </PolicyText>
                    </RefundContainer>

                </Container>
            )
    }
};


const Container = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    margin: 40px 0 15px 0;

    @media (max-width: 768px) {
        display: block;
    }
`;

const PolicyContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 8px;
    padding: 8px 12px;
    margin-right: 25px;
    box-shadow: 0 0 11px rgb(0 0 0 / 82%), 0 2px 5px rgb(0 0 0 / 10%);

    @media (max-width: 768px) {
        margin: 0px 20px 30px 20px;
        padding-bottom: 20px;
    }
`;

const PolicyTitle = styled.div`
    text-align: center;
    color: ${(props) => props.theme.colors.highlight};
    font-size: 24px;
    padding: 10px 0;
    font-weight: 600;
`;

const PolicySubTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.font};
`;

const PolicyText = styled.div`
    flex: 1;
    color: ${(props) => props.theme.colors.font};
    font-size: 16px;
    margin: 3px 0;
`;

const PolicyLink = styled.a`
    color: ${(props) => props.theme.colors.highlight};
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }
`;

const PolicyBulletContainer = styled.div`
    display: flex;
    margin-top: 8px;
    color: ${(props) => props.theme.colors.font};
    flex-direction: column;
`;

const PolicyBulletBlock = styled.div`
    display: flex;
    margin: 2px 0;
`;

const PolicyBullet = styled.img`
    width: 20px;
    height: 20px;
`;

const PolicyBulletText = styled.div`
    flex: 1;
`;

const RefundContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 8px;
    padding: 8px 12px;
    box-shadow: 0 0 11px rgb(0 0 0 / 82%), 0 2px 5px rgb(0 0 0 / 10%);

    @media (max-width: 768px) {
        margin: 30px 20px;
    }
`;

const RefundSubTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.font};
    margin: 8px 0 0 0;
`;