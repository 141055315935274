// Import node_modules
import React from 'react';
import styled from "styled-components";
// Import components
// import DarkModeToggle from "react-dark-mode-toggle";

// Import images
import Logo from './../../Assets/Icons/Logo.png';

export class Footer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            theme: true
        };
    }   

    componentDidMount(){
        let localTheme = window.localStorage.getItem('theme') === 'dark' ? true : false;
        this.setState({theme: localTheme});
    }

    render() {
        let year = new Date().getFullYear();
        return (
            <Container>
                <LinksContainer>
                    <ContainerTitle href="mailto:help@amaribot.com">help@amaribot.com</ContainerTitle>
                    {/* <ContainerTitle href="/privacy">Privacy policy</ContainerTitle> */}
                    <ContainerTitle href="/refund">Refund policy</ContainerTitle>
                </LinksContainer>
                
                <CopyRightContainer>
                    <CopyRightImageContainer>
                        {/* {this.state.theme === true ? (
                            <CopyRightImage src={Logo} alt="AmariLogo" onClick={() => {
                                let newTheme = !this.state.theme;
                                this.setState({theme: newTheme});
                                let localNewTheme = (newTheme === true) ? 'dark' : 'light';
                                window.localStorage.setItem('theme', localNewTheme);
                                this.props.routerParent.setState({theme: localNewTheme})
                            }} />
                        ) : (
                            <CopyRightImage src={Logo} alt="AmariLogo" onClick={() => {
                                let newTheme = !this.state.theme;
                                this.setState({theme: newTheme});
                                let localNewTheme = (newTheme === true) ? 'dark' : 'light';
                                window.localStorage.setItem('theme', localNewTheme);
                                this.props.routerParent.setState({theme: localNewTheme})
                            }} />
                        )} */}
                        
                        <CopyRightImage src={Logo} alt="AmariLogo" />
                    </CopyRightImageContainer>
                    <CopyRightText>
                        © {year} AmariBot. All rights reserved.
                    </CopyRightText>
                </CopyRightContainer>
                
                <ExtraContainer>
                    <ContainerTitle href="https://twitter.com/hiimamaribot?lang=en" target="_blank">Twitter</ContainerTitle>
                    <ContainerTitle href="https://discord.gg/kqefESMzQj" target="_blank">Support server</ContainerTitle>
                    {/* <ThemeContainer>
                        <DarkModeToggle
                            onChange={() => {
                                let newTheme = !this.state.theme;
                                this.setState({theme: newTheme});
                                let localNewTheme = (newTheme === true) ? 'dark' : 'light';
                                window.localStorage.setItem('theme', localNewTheme);
                                this.props.routerParent.setState({theme: localNewTheme})
                            }}
                            checked={this.state.theme}
                            size={55}
                        />
                        <ThemeTag>Theme</ThemeTag>
                    </ThemeContainer> */}
                </ExtraContainer>
            
            </Container>
        )
    }
};


const Container = styled.div`
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: ${(props) => props.theme.colors.font};
    margin: 15px 0 0 0;
    padding-bottom: 15px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ContainerTitle = styled.a`
    font-size: 18px;
    padding: 3px 0;
    text-decoration: none;
    color: ${(props) => props.theme.colors.font};
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`;

const CopyRightContainer = styled.div`
    flex: 1;
    text-align: center;

    @media (max-width: 768px) {
        order: 1;
        margin-bottom: 20px;
    }
`;

const CopyRightImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CopyRightImage = styled.img`
    width: 60px;
    height: auto;
    padding: 15px 0;
    transition: transform .65s ease-in-out;

    &:hover{
        transition: transform .65s ease-in-out;
        transform: rotate(1turn);
        cursor: pointer;
    }
`;

const CopyRightText = styled.div`
    font-size: 18px;
`;

const ExtraContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 768px) {
        order: 2;
        margin-bottom: 20px;
    }
`;

const ThemeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
`;

const ThemeTag = styled.div`
    padding-left: 10px;
    font-size: 18px;
`;

const LinksContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    @media (max-width: 768px) {
        order: 3;
    }
`;