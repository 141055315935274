// Import node_modules
import React from 'react';
import styled, { createGlobalStyle } from "styled-components";

// Import components
import { commandsList } from './CommandsList.js';
import GoogleAdSense from './../../Utils/GoogleAdsense';

// Import css
import './../../Assets/CSS/Commands.css'


export class CommandsContent extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            category: "All",
            list: ["All", "Admin", "General", "Donator"],
            activeCommands: commandsList
        };
    }

    render() {
        let newList = this.props.commands.map((x, i) => {
            if(this.state.category === "All"){
                return (
                    <CommandContent key={i}>
                        <Command onClick={() => {
                            let newList = this.props.commands;
                            newList[i].visible = !this.props.commands[i].visible;
                            this.props.parentObject.setState({commands: newList});
                        }} className={this.props.commands[i].visible ? "command-visible" : "command-hidden"}>
                            <CommandContainer>
                                <CommandName onClick={() => {window.navigator.clipboard.writeText(`:?${x.name.toLowerCase()}`)}}>
                                    {x.name}
                                    <TooltipContainer>Copy command</TooltipContainer>
                                </CommandName> - {x.description}
                            </CommandContainer>
                            {!x.permission ? null : <CommandTag>{x.permission}</CommandTag>}
                        </Command>
                        <CommandInfoContainer className={this.props.commands[i].visible ? "command-content-visible" : "command-content-hidden"}>
                            <CommandInfoTitle>
                                Usage
                            </CommandInfoTitle>
                            {x.content && x.content.map((j, z) => (
                                <div key={z}>
                                    <CommandInfoSubtitle>
                                        {j.usage}
                                    </CommandInfoSubtitle>
                                    <CommandInfo>
                                        {j.example && j.example.map((k, p) => (
                                            <CommandBlock key={p}>{k}</CommandBlock>
                                        ))}
                                    </CommandInfo>
                                </div>
                            ))}
                        </CommandInfoContainer>
                    </CommandContent>
                );
            }else if(this.state.category === x.category){
                return (
                    <CommandContent key={i}>
                        <Command onClick={() => {
                            let newList = this.props.commands;
                            newList[i].visible = !this.props.commands[i].visible;
                            this.props.parentObject.setState({commands: newList});
                        }} className={this.props.commands[i].visible ? "command-visible" : "command-hidden"}>
                            <CommandContainer>
                                <CommandName onClick={() => {window.navigator.clipboard.writeText(`:?${x.name.toLowerCase()}`)}}>
                                    {x.name}
                                    <TooltipContainer>Copy command</TooltipContainer>
                                </CommandName> - {x.description}
                            </CommandContainer>
                            {!x.permission ? null : <CommandTag>{x.permission}</CommandTag>}
                        </Command>
                        <CommandInfoContainer className={this.props.commands[i].visible ? "command-content-visible" : "command-content-hidden"}>
                            <CommandInfoTitle>
                                Usage
                            </CommandInfoTitle>
                            {x.content && x.content.map((j, z) => (
                                <div key={z}>
                                    <CommandInfoSubtitle>
                                        {j.usage}
                                    </CommandInfoSubtitle>
                                    <CommandInfo>
                                        {j.example && j.example.map((k, p) => (
                                            <CommandBlock key={p}>{k}</CommandBlock>
                                        ))}
                                    </CommandInfo>
                                </div>
                            ))}
                        </CommandInfoContainer>
                    </CommandContent>
                )
            }else{
                return null;
            };
        });

        return (
            <Container>
                <CategoryContainer>
                    <CategoryContent>
                        <CategoryBlock>
                            <CategoryTitle>Categories</CategoryTitle>
                            {this.state.list && this.state.list.map((y, i) => {
                                if(this.state.category === y){
                                    return (
                                        <ActiveCategory onClick={() => this.setState({category: y})} key={i}>{y}</ActiveCategory>
                                    )
                                }
                                return (
                                    <Category onClick={() => {
                                        this.setState({category: y})
                                        let newCmdList = this.props.commands.map(commandObj => {
                                            commandObj.visible = false;
                                            return commandObj;
                                        });
                                        this.props.parentObject.setState({commands: newCmdList});
                                    }} key={i}>{y}</Category>
                                );
                            })}
                        </CategoryBlock>
                    </CategoryContent>
                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>
                </CategoryContainer>

                <CommandsContainer>
                    {newList.length < 1 ? null : newList}
                </CommandsContainer>

            </Container>
        )
    }
};


const Container = styled.div`
    width: 100%;
    display: flex;
    color: ${(props) => props.theme.colors.font};

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CategoryContent = styled.div`
    max-height: min-content;
    margin: 20px 20px 20px 0;
    min-width: 250px;
    width: 250px;
    max-width: 250px;
    border-radius: 8px;

    @media (max-width: 980px) {
        min-width: 200px;
        width: 200px;
        max-width: 200px;
    }

    @media (max-width: 800px) {
        min-width: 150px;
        width: 150px;
        max-width: 150px;
    }

    @media (max-width: 768px) {
        flex: 1;
        max-width: none;
        margin: 20px 0;
    }
`;

const CategoryBlock = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
`;

const Category = styled.div`
    background-color: ${(props) => props.theme.colors.light};
    font-size: 18px;
    margin: 12px 0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 12px 15px -15px rgb(0 0 0 / 80%);
    position: relative;

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const ActiveCategory = styled.div`
    background-color: ${(props) => props.theme.colors.highlight}ea;
    font-size: 18px;
    margin: 12px 0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 12px 15px -15px rgb(0 0 0 / 80%);

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const CategoryTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
`

const CommandsContainer = styled.div`
    flex: 1;
    display: flex;
    margin: 20px 0;
    flex-direction: column;
    min-height: 82vh;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 768px) {
        overflow: auto;
        max-height: none;
    }

`;

const CommandContent = styled.div`
    max-height: min-content;
    margin-right: 6px;
    margin-top: 5px;
`;

const TooltipContainer = styled.span`
    visibility: visible;
    display: none;
    background-color: ${(props) => props.theme.colors.highlight};
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    z-index: 99;
    position: absolute;
    margin-left: 15px;
    margin-top: -5px;

    &::after{
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent ${(props) => props.theme.colors.highlight} transparent transparent;
        color: ${(props) => props.theme.colors.highlight};
        z-index: 99;
    }
`;

const CommandName = styled.div`
    display: inline-block;
    font-size: 16px;
    background-color: ${(props) => props.theme.colors.light};
    padding: 8px;
    border-radius: 5px;
    color: ${(props) => props.theme.colors.secondaryFont};
    font-family: 'Montserrat';
    border-left: 5px solid ${(props) => props.theme.colors.highlight};
    margin-right: 5px;
    &:hover ${TooltipContainer}{
        display: inline-block;
    }
`;

const CommandInfoContainer = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    padding: 15px;
`;

const CommandContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`

const Command = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.secondary};
    margin: 5px 0 0 0;
    padding: 15px;
    border-radius: 8px;
    font-family: 'Montserrat';
    z-index: 99;
    align-items: center;
    &:hover{
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.secondary}d5;
    }

    &:hover ${CommandName}{
        color: ${(props) => props.theme.colors.highlight};
    }
`;

const CommandTag = styled.div`
    background-color: ${(props) => props.theme.colors.light};
    padding: 7px 8px;
    border-radius: 8px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.secondaryFont};
`;

const CommandInfoTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
`;

const CommandInfoSubtitle = styled.div`
    font-size: 16px;
`;

const CommandInfo = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 8px;
    padding: 8px;
    color: ${(props) => props.theme.colors.highlight};
    margin: 15px 0px;
`;

const CommandBlock = styled.div`
    display: block;
    padding: 1px 0px;
`;

const AdContent = styled.div`
    max-height: min-content;
    min-width: 250px;
    width: 250px;
    max-width: 250px;
    text-align: center;

    @media (max-width: 980px) {
        min-width: 200px;
        width: 200px;
        max-width: 200px;
    }

    @media (max-width: 800px) {
        min-width: 150px;
        width: 150px;
        max-width: 150px;
    }

    @media (max-width: 768px) {
        flex: 1;
        max-width: none;
        margin: 20px 0;
    }
`;
