import React from 'react';

export let commandsList = [
    {
        name: "Announce",
        description: "Customize the level up message and where it goes!",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Sets the channel where announcement messages appear.",
                example: [":?announce set <#channel>"]
            },
            {
                usage: "Sets the announcement messages to be sent via DM.",
                example: [":?announce set dm"]
            },
            {
                usage: "Turns off all announcement messages.",
                example: [":?announce set off"]
            },
            {
                usage: (<div><code>Default</code> Sets the announcement messages to show up where the user levels up.</div>),
                example: [":?announce refresh"]
            },
            {
                usage: "Pings the user in the announcement message.",
                example: [":?announce ping <on/off>"]
            },
            {
                usage: "Sets a custom announcement message.",
                example: [":?announce custom *", ":?announce custom <text>"]
            },
            {
                usage: "Sets a custom announcement message at each level.",
                example: [":?announce level *", ":?announce level add <level> <text>", ":?announce level remove <level>", ":?announce level test <level>", ":?announce level show"]
            }
        ],
        visible: false
    },
    {
        name: "Blacklist",
        description: "Blacklist a role or a channel to no longer receive EXP",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Adds a role to the blacklist. Blacklisted roles will not get exp.",
                example: [":?blacklist <add/remove> <@role/ID>"]
            },
            {
                usage: "Adds a channel to the blacklist. Blacklisted channels will not get exp.",
                example: [":?blacklist <add/remove> <#channel/ID>"]
            },
            {
                usage: "Shows all blacklisted roles and channels.",
                example: [":?blacklist show"]
            }
        ],
        visible: false
    },
    {
        name: "Bug",
        description: "Report a bug to the developers",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Submit a bug report.",
                example: [":?bug <text>"]
            }
        ],
        visible: false
    },
    {
        name: "Coleave",
        description: "Turn off/on to clear a users points upon leaving",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Sets to on or off the option to clear user exp when they leave the server.",
                example: [":?coleave <on/off>"]
            }
        ],
        visible: false
    },
    {
        name: "Cooldown",
        description: "Changes the cooldown between messages that will give users EXP",
        category: "Donator",
        permission: "Owner",
        content: [
            {
                usage: "Changes the cooldown between messages that will give users exp (any value between 4 to 500 seconds, default is 8 seconds).",
                example: [":?cooldown <4 - 500>"]
            }
        ],
        visible: false
    },
    {
        name: "Donate",
        description: "Check out AmariBot's donation options.",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Check out AmariBot's donation options.",
                example: [":?donate"]
            }
        ],
        visible: false
    },
    {
        name: "Givelevel",
        description: "Sets a user to the specified level.",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Sets a user to the specified level.",
                example: [":?givelevel <@user> <level>"]
            }
        ],
        visible: false
    },
    {
        name: "Help",
        description: "Get information about a command",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Lists all commands.",
                example: [":?help"]
            },
            {
                usage: "Gives information about a specific command.",
                example: [":?help <command>"]
            }
        ],
        visible: false
    },
    {
        name: "Invite",
        description: "Get a invite link for the bot",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Get a link to add AmariBot to your server.",
                example: [":?invite"]
            }
        ],
        visible: false
    },
    {
        name: "Joinrole",
        description: "Set a role for users to recieve when they join",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Sets a role for users to gain when they join the server.",
                example: [":?joinrole <@role/ID>", ":?joinrole remove", ":?joinrole show"]
            }
        ],
        visible: false
    },
    {
        name: "Leaderboard",
        description: "Show the leaderboard for this server",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Shows the leaderboard for this server.",
                example: [":?leaderboard"]
            },
            {
                usage: "If enabled, shows the weekly leaderboard for this server.",
                example: [":?leaderboard weekly"]
            }
        ],
        visible: false
    },
    {
        name: "Modifyexp",
        description: "Add or remove exp from a specific user",
        category: "Donator",
        permission: "Admin",
        content: [
            {
                usage: "Adds or removes exp from the specified user.",
                example: [":?modifyexp <add/remove> <1 - 10000> <@user>"]
            }
        ],
        visible: false
    },
    {
        name: "Pmulti",
        description: "Add a multiplier to roles/channels",
        category: "Donator",
        permission: "Owner",
        content: [
            {
                usage: "Sets this server's global multiplier.",
                example: [":?pmulti set <1-100>"]
            },
            {
                usage: "Sets different exp rates for each channel or role.",
                example: [":?pmulti add <#channel/@role> <1-100>"]
            },
            {
                usage: "Clears a channel or role custom multiplier (resets to current global multiplier).",
                example: [":?pmulti remove <#channel/@role>"]
            },
            {
                usage: "Shows all multipliers for channels and roles.",
                example: [":?pmulti show"]
            }
        ],
        visible: false
    },
    {
        name: "Prefix",
        description: "Change the prefix for the bot",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Changes AmariBot's prefix.",
                example: [":?prefix <prefix>"]
            }
        ],
        visible: false
    },
    {
        name: "Profile",
        description: "Change the color/opacity/background of the rank card",
        category: "General",
        permission: "",
        content: [
            {
                usage: (
                    <div>
                        Change your profile color to <a style={{color:'#FCEAA8'}}>"default",</a> <a style={{color:'teal'}}>"teal"</a>, <a style={{color:'green'}}>"green"</a>, <a style={{color:'cyan'}}>"blue"</a>, <a style={{color:'purple'}}>"purple"</a>, <a style={{color:'pink'}}>"pink"</a>, <a style={{color:'yellow'}}>"yellow"</a>, <a style={{color:'orange'}}>"orange"</a>, <a style={{color:'red'}}>"red"</a> or <a style={{color:'gray'}}>"gray"</a>.
                    </div>
                ),
                example:[ ":?profile color default"]
            },
            {
                usage: (<div><code>Donators</code> Change your profile color using hex color codes.</div>),
                example: [":?profile color #ea8465"]
            },
            {
                usage: (<div><code>Donators</code> Change your profile background.</div>),
                example: [":?profile bg"]
            },
            {
                usage: (<div><code>Donators</code> Change your profile's opacity.</div>),
                example: [":?profile opacity <0 - 100>"]
            }
        ],
        visible: false
    },
    {
        name: "Rank",
        description: "Check your current rank or another users current rank",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Show your own rank card.",
                example: [":?rank"]
            },
            {
                usage: "Shows a user's rank card.",
                example: [":?rank <@user>"]
            }
        ],
        visible: false
    },
    {
        name: "Reset",
        description: "Reset user points or server settings",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Resets the specified user's exp.",
                example: [":?reset <@user>"]
            },
            {
                usage: "Resets all users exp.",
                example: [":?reset points"]
            },
            {
                usage: "Resets all the settings for this server.",
                example: [":?reset settings"]
            },
            {
                usage: "Resets all users exp and this server's settings.",
                example: [":?reset all"]
            }
        ],
        visible: false
    },
    {
        name: "Rlevel",
        description: "Set up roles to auto assign when users reach a specific level",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Sets a role to be given once a user reaches the specified level.",
                example: [":?rlevel add <level> <@role>"]
            },
            {
                usage: "Removes the role set at the specified level.",
                example: [":?rlevel remove <level>"]
            },
            {
                usage: (<div><code>Default</code> Sets roles to accumulate, keeping all roles.</div>),
                example: [":?rlevel accumulate"]
            },
            {
                usage: "Sets roles to replace upon the next level role.",
                example: [":?rlevel replace"]
            },
            {
                usage: "Shows all roles set to levels.",
                example: [":?rlevel show"]
            }
        ],
        visible: false
    },
    {
        name: "Settings",
        description: "Check the servers current settings",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Access AmariBot's settings for this server.",
                example: [":?settings"]
            }
        ],
        visible: false
    },
    {
        name: "Support",
        description: "Get a discord link to join our support server",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Get a link to join the support server.",
                example: [":?support"]
            }
        ],
        visible: false
    },
    {
        name: "Update",
        description: "Get information about the latest update",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Get the latest AmariBot update post.",
                example: [":?update"]
            }
        ],
        visible: false
    },
    {
        name: "Vote",
        description: "Get a link to upvote for the bot",
        category: "General",
        permission: "",
        content: [
            {
                usage: "Get a link to vote on AmariBot.",
                example: [":?vote"]
            }
        ],
        visible: false
    },
    {
        name: "Weekly",
        description: "Weekly command allows you to keep track of exp over a short amount of time",
        category: "Admin",
        permission: "",
        content: [
            {
                usage: "Turns the weekly leaderboard on or off.",
                example: [":?weekly <on/off>"]
            },
            {
                usage: "Announces the Top 3 winners of the week and resets the leaderboard. If unannounced, users exp will continue to accumulate and the leaderboard will not reset.",
                example: [":?weekly winners"]
            },
            {
                usage: "If enabled, shows the weekly leaderboard for this server.",
                example: [":?weekly leaderboard"]
            }
        ],
        visible: false
    },
    {
        name: "Voice",
        description: "Allows users to earn EXP through voice channels",
        category: "Donator",
        permission: "Admin",
        content: [
            {
                usage: "Enable or Disable Voice Exp (default is disabled).",
                example: [":?voice enable / disable"]
            },
            {
                usage: "Exp rate for voice exp (default 1) (exp rate * cooldown).",
                example: [":?voice exp <1-100>"]
            },
            {
                usage: "Cooldown for voice exp (in minutes) (default 2).",
                example: [":?voice cooldown <1 - 60>"]
            }
        ],
        visible: false
    }
]