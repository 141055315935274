// Import node_modules
import React from 'react';
import styled from "styled-components";
import { MdCancel, MdCheckCircle } from 'react-icons/md';

// Import class
import './Contact.css';

export class ApproveCoowner extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
            error: false
        };
    }

    async componentDidMount(){
        if(!this.props.user.isLogged){
            return window.location.href = "/login?redirect=/approve/coowner";
        };
        let query = await fetch(`/json/coowner/applications`);
        if(query.status !== 200){
            return this.setState({error: true});
        };
        query = await query.json();
        this.setState({ applications: query.list });
    };

    sendEmail(){
        
    };

    render() {
        return (
            <Container>
                <Title>Co-owner Applications</Title>
                <Content>
                    {/* <AppContainer>
                        <AppAuthorInfo>
                            <AppAuthorBlock>
                                Info:
                            </AppAuthorBlock>
                        </AppAuthorInfo>
                        <AppText>Message:</AppText>
                        <AppButtons>
                        </AppButtons>
                    </AppContainer> */}
                    {this.state.applications && this.state.applications.map((item, index) => (
                        <AppContainer key={index}>
                            <AppAuthorInfo>
                                <AppAuthorBlock>
                                    {item.guild.name}
                                </AppAuthorBlock>
                                <AppAuthorBlock>
                                    {item.user.username}#{item.user.discriminator}
                                </AppAuthorBlock>
                            </AppAuthorInfo>
                            <AppText>{item.message}</AppText>
                            <AppCancelButtons>
                                <MdCancel size={32} onClick={async() => {

                                }} />
                            </AppCancelButtons>
                            <AppApproveButtons>
                                <MdCheckCircle size={32} onClick={async() => {
                                    let query = await fetch(`/json/coowner/update/${item.guild.id}/${item.donator}`);
                                }} />
                            </AppApproveButtons>
                        </AppContainer>
                    ))}
                </Content>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.colors.font};
    font-family: "Montserrat";
    border-radius: 16px;
    @media (max-width: 768px) {
        background-color: ${(props) => props.theme.colors.primary};
        box-shadow: none;
    }
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 12%;
    width: 76%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }

`;

const Title = styled.h1`
    margin: 40px 0 10px 0;
    text-align: center;
`;

const AppContainer = styled.div`
    display: flex;
    width: 100%;
`;

const AppAuthorInfo = styled.div`
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.secondary};
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
`;

const AppAuthorBlock  = styled.div`

`;

const AppText = styled.div`
    flex: 1;
    padding: 10px 5px;
    background-color: ${(props) => props.theme.colors.secondary};
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
`;

const AppCancelButtons = styled.div`
    padding: 10px 5px;
    background-color: ${(props) => props.theme.colors.secondary};
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
    color: #ed4b32;

    &:hover{
        color: #c9321b;
        cursor: pointer;
    }
`;

const AppApproveButtons = styled.div`
    padding: 10px 5px;
    background-color: ${(props) => props.theme.colors.secondary};
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
    color: #87cd1f;

    &:hover{
        color: #69a70e;
        cursor: pointer;
    }
`;