const icons = [
    require('./Amari.png'),
    require('./AmariChan.png'),
    require('./Amaristein.png'),
    require('./Excited.png'),
    require('./Happy.png'),
    require('./HappyCry.png'),
    require('./Love.png'),
    require('./Mummy.png'),
    require('./Party.png'),
    require('./Rip.png'),
    require('./Secretive.png'),
    require('./Smart.png')
]


export default icons;