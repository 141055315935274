// Import node_modules
import React from 'react';
import styled from "styled-components";

export class Topgg extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {logo: 0};
    }

    render() {
        return (
            <Container>
                <a href="https://discordbots.org/bot/339254240012664832">
                    <img src="https://discordbots.org/api/widget/339254240012664832.svg" alt="AmariBot" />
                </a>
            </Container>
        )
    }
};

const Container = styled.div`
    width: 100%;
    text-align: center;
    margin: 20px 0 50px 0;
`;