// Chart-js??
// Idk what to use yet so have fun finding things :)
// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';

// Import chart tpyes
import { BasicLine } from './BasicLine.jsx';
import { DoughnutChart } from './Doughnut.jsx';
import { Dynamic } from './Dynamic.jsx';
import { HorizontalBar } from './HorizontalBar.jsx';
import { MultiAxis } from './MultiAxisLine.jsx';
import { VerticalBar } from './VerticalBar.jsx';

export class Statistics extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }


    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>
                    <Content>
                        <BasicLine />
                        <MultiAxis />
                    </Content>
                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    max-width: 1920px;
    background-color: ${(props) => props.theme.colors.primary};
`;

const Content = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;