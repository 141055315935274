// Import node_modules
import React from 'react';
import styled from "styled-components";

//Import images
import NBA from './../../Assets/Icons/NBA.png';
import PUBG from './../../Assets/Icons/Pubg.png';
import GREFG from './../../Assets/Icons/Grefg.png';
import HYPE from './../../Assets/Icons/Mimu.png';

export class UsedBy extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {logo: 0};
    }

    render() {
        return (
            <Container>
                <Content>
                    <TopTitle>
                        Used by 140,000+ servers
                    </TopTitle>
                    <TopImageContainer>
                        <TopImage src={NBA} alt="" />
                        <TopImage src={PUBG} alt="" />
                        <TopImage src={GREFG} alt="" />
                        <TopImage src={HYPE} alt="" />
                    </TopImageContainer>
                </Content>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.secondary};
    max-width: 1920px;
`;


const Content = styled.div`
    margin: 0 23% 0 20%;
    width: 70%;
    height: auto;
    display: flex;
`;

const TopTitle = styled.h1`
    flex: 1;
    color: ${(props) => props.theme.colors.font};
    display: flex;
    align-items: center;
`;

const TopImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const TopImage = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 6px;
    margin: 30px 15px;
`