// Import node_modules
import React from 'react';
import styled from "styled-components";
import AvatarIcon from 'react-avatar';

// Import components
import { Loading } from '../Loading.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

// Import images
import Logo from './../../Assets/Icons/Logo.png';
import Icons from '../../Assets/Icons/Leaderboard';

const colors = {
    0: "#daa520",
    1: "#D8D8D8",
    2: "#cd7f32",
}

export class WeeklyLeaderboard extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            leaderboard: {
                data: [],
                count: 0,
                total_count: 0,
                guild: {}
            },
            page: 1,
            leaderboardLoading: false,
            fullyLoaded: false
        };
    };

    async componentDidMount(){
        let guild = !this.props.match.params.id ? this.props.history.push('/404') : this.props.match.params.id;
        let leaderboard = await fetch(`/guild/leaderboard/weekly/${guild}?page=${this.state.page}`);
        if(leaderboard.status !== 200){
            this.props.history.push('/404');
        }
        leaderboard = await leaderboard.json();
        if(leaderboard.count < 50) {
            this.setState({leaderboard: leaderboard, isLoading: false, fullyLoaded: true});
        }else{
            this.setState({leaderboard: leaderboard, isLoading: false});
        };
        window.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.onScroll, false);
    }

    onScroll = async() => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if(bottom && (this.state.leaderboardLoading === false) && (this.state.fullyLoaded === false)){
            this.setState({leaderboardLoading: true});
            setTimeout(async() => {
                let data = this.state.leaderboard.data;
                let leaderboard = await fetch(`/guild/leaderboard/weekly/${this.props.match.params.id}?page=${(this.state.page+1)}`);
                if(leaderboard.status !== 200) return;
                leaderboard = await leaderboard.json();
                leaderboard.data = data.concat(leaderboard.data);
                if(leaderboard.count < 50) {
                    this.setState({leaderboard: leaderboard, page: (this.state.page + 1), leaderboardLoading: false, fullyLoaded: true});
                }else{
                    this.setState({leaderboard: leaderboard, page: (this.state.page + 1), leaderboardLoading: false});
                };
            }, 100)
        }
    };

    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>
                    <AvatarContent>
                        <AvatarIcon size={90} name={this.state.leaderboard.guild.name.replace(/ /g, "")} src={this.state.leaderboard.guild.icon} round={true} />
                        <Title>{this.state.leaderboard.guild.name}</Title>
                    </AvatarContent>
                    
                    <Content>
                        <GoogleAdSense />
                    </Content>
                    
                    <Content>
                        <LeaderboardContainer>
                            {this.state.leaderboard && this.state.leaderboard.data.map((member, key) => {
                                if(key === 0 || key === 1 || key === 2){
                                    return (
                                        <MemberContainer key={key}>
                                            <MemberPosition>
                                                <MemberPositionTitle style={{color: colors[key]}}>Rank</MemberPositionTitle>
                                                <MemberPositionText>{(key+1)}</MemberPositionText>
                                            </MemberPosition>
                                            <AvatarContainer>
                                                <AvatarIcon size={48} name={member.username.replace(/ /g, "")} src={Icons[member.avatar].default} round={true} />
                                            </AvatarContainer>
                                            <MemberName>{member.username}</MemberName>
                                            <MemberLevel>
                                                <MemberLevelTitle></MemberLevelTitle>
                                                <MemberLevelText>{member.exp}</MemberLevelText>
                                            </MemberLevel>
                                        </MemberContainer>
                                    )
                                }else{
                                    if((key%50) === 0){
                                        return (
                                            <div style={{textAlign: 'center'}}>
                                                <MemberContainer key={key}>
                                                    <MemberPosition>
                                                        <MemberPositionTitle>Rank</MemberPositionTitle>
                                                        <MemberPositionText>{(key+1)}</MemberPositionText>
                                                    </MemberPosition>
                                                    <AvatarContainer>
                                                        <AvatarIcon size={48} name={member.username.replace(/ /g, "")} src={Icons[member.avatar].default} round={true} />
                                                    </AvatarContainer>
                                                    <MemberName>{member.username}</MemberName>
                                                    <MemberLevel>
                                                        <MemberLevelTitle></MemberLevelTitle>
                                                        <MemberLevelText>{member.exp}</MemberLevelText>
                                                    </MemberLevel>
                                                </MemberContainer>
                                                <GoogleAdSense />
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <MemberContainer key={key}>
                                                <MemberPosition>
                                                    <MemberPositionTitle>Rank</MemberPositionTitle>
                                                    <MemberPositionText>{(key+1)}</MemberPositionText>
                                                </MemberPosition>
                                                <AvatarContainer>
                                                    <AvatarIcon size={48} name={member.username.replace(/ /g, "")} src={Icons[member.avatar].default} round={true} />
                                                </AvatarContainer>
                                                <MemberName>{member.username}</MemberName>
                                                <MemberLevel>
                                                    <MemberLevelTitle></MemberLevelTitle>
                                                    <MemberLevelText>{member.exp}</MemberLevelText>
                                                </MemberLevel>
                                            </MemberContainer>
                                        )
                                    }
                                }
                            })}
                            {this.state.leaderboardLoading === true ? (<LoadingContainer>
                                <LoadingImage src={Logo} />
                            </LoadingContainer>) : null}
                        </LeaderboardContainer>
                    </Content>

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    min-height: 88vh;
`;

const Content = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const AdContent = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 20px;
`;

const LeaderboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.font};
`;

const MemberContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.secondary};
    border-bottom: 1px solid ${(props) => props.theme.colors.light};
    justify-content: center;
    align-items: center;
`;

const MemberPositionText = styled.div`
    display: flex;
    font-weight: bold;
`;

const MemberPosition = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
`;

const MemberName = styled.div`
    display: flex;
    flex: 1;
    font-size: 18px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const MemberLevel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
`;

const MemberLevelText = styled.div`
    display: flex;
    font-weight: bold;
    color: ${(props) => props.theme.colors.highlight};
`;

const MemberLevelTitle = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.secondaryFont};
    margin-bottom: 3px;
    &::after{
        content: 'Experience'
    };

    @media (max-width: 450px) {
        &::after{
            content: 'EXP'
        };
    }

`;

const MemberPositionTitle = styled.div`
    color: ${(props) => props.theme.colors.secondaryFont};
    margin-bottom: 3px;
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingImage = styled.img`
    width: 60px;
    height: auto;
    padding: 20px 0;
    animation:spin 2s linear infinite;

    @keyframes spin {
    0%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
    10%   {-webkit-transform: rotate(40deg); transform:rotate(40deg);}
    20%   {-webkit-transform: rotate(80deg); transform:rotate(80deg);}
    30%   {-webkit-transform: rotate(120deg); transform:rotate(120deg);}
    40%   {-webkit-transform: rotate(160deg); transform:rotate(160deg);}
    50%   {-webkit-transform: rotate(200deg); transform:rotate(200deg);}
    60%   {-webkit-transform: rotate(240deg); transform:rotate(240deg);}
    70%   {-webkit-transform: rotate(280deg); transform:rotate(280deg);}
    80%   {-webkit-transform: rotate(320deg); transform:rotate(320deg);}
    90%   {-webkit-transform: rotate(360deg); transform:rotate(360deg);}
    100%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
}

@-webkit-keyframes spin {
    0%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
    10%   {-webkit-transform: rotate(40deg); transform:rotate(40deg);}
    20%   {-webkit-transform: rotate(80deg); transform:rotate(80deg);}
    30%   {-webkit-transform: rotate(120deg); transform:rotate(120deg);}
    40%   {-webkit-transform: rotate(160deg); transform:rotate(160deg);}
    50%   {-webkit-transform: rotate(200deg); transform:rotate(200deg);}
    60%   {-webkit-transform: rotate(240deg); transform:rotate(240deg);}
    70%   {-webkit-transform: rotate(280deg); transform:rotate(280deg);}
    80%   {-webkit-transform: rotate(320deg); transform:rotate(320deg);}
    90%   {-webkit-transform: rotate(360deg); transform:rotate(360deg);}
    100%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
}

@-moz-keyframes spin {
    0%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
    10%   {-webkit-transform: rotate(40deg); transform:rotate(40deg);}
    20%   {-webkit-transform: rotate(80deg); transform:rotate(80deg);}
    30%   {-webkit-transform: rotate(120deg); transform:rotate(120deg);}
    40%   {-webkit-transform: rotate(160deg); transform:rotate(160deg);}
    50%   {-webkit-transform: rotate(200deg); transform:rotate(200deg);}
    60%   {-webkit-transform: rotate(240deg); transform:rotate(240deg);}
    70%   {-webkit-transform: rotate(280deg); transform:rotate(280deg);}
    80%   {-webkit-transform: rotate(320deg); transform:rotate(320deg);}
    90%   {-webkit-transform: rotate(360deg); transform:rotate(360deg);}
    100%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
}
`;

const AvatarContainer = styled.div`
    padding: 0 15px 0 0;

    @media (max-width: 768px) {
        display: none;
    }
`;

const AvatarContent = styled.div`
    margin: 20px 10%;
    margin-top: 30px;
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;