// Import node_modules
import React from 'react';
import styled from "styled-components";
import PaypalButton from './../../Utils/PayPal.jsx';

// Import images
import Banner from './../../Assets/Icons/Banner.png';
import AmariLove from './../../Assets/Icons/Love.png';

export class Modal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            approved: false
        };
    }

    render() {
            return (
                <Container>
                    <Content>
                        <ModalTopContainer>
                            <ModalTopImage src={Banner} alt="" />
                            <ModalCloseButton onClick={() => {
                                this.props.parentObject.setState({processPayment: false});
                                this.setState({approved: false});
                            }}>x</ModalCloseButton>
                        </ModalTopContainer>
                        {this.state.approved === false ? (
                            <div>
                                <ModalPaymentAmount>
                                    ${this.props.parentObject.state.amount.toLocaleString()}
                                </ModalPaymentAmount>
                                <ModalPaymentTitle>
                                    {this.props.parentObject.state.amount < 5 ? 'Donation' : 'Premium Perks'}
                                </ModalPaymentTitle>
                                <ModalPaymentSubtitle>
                                    Thank you for supporting AmariBot. By proceeding, you are indicating your agreement with <Link href="/refund" target="_blank">refund policy</Link>.
                                </ModalPaymentSubtitle>
                                <PaypalButtonContainer>
                                    <PaypalButton payment={this.props.parentObject.state} user={this.props.user} parentObject={this} />
                                </PaypalButtonContainer>
                            </div>
                        ) : (
                            <div>
                                <ThanksContainer>
                                    <ThanksImage src={AmariLove} alt="AmariLove" />
                                    <ThanksTitle>Thank you for donating!</ThanksTitle>
                                    <ThanksText>Your payment was successful! Your donator perks will be active within 10-15 minutes after completing donation. If you have any issue, please contact our staff team in our support server.</ThanksText>
                                </ThanksContainer>
                            </div>
                        )}
                    </Content>
                </Container>
            )
    }
};


const Container = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    transition: 5s;
`;

const Content = styled.div`
    position: relative;
    background-color: #f4f5f5;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    min-width: 250px;
    max-width: 450px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
`;

const ModalTopContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dedede;
    background-color: #ffffff;
    padding: 10px 0;
`;

const ModalTopImage = styled.img`
    width: 40%;
    max-width: 150px;
    height: auto;
`;

const ModalPaymentAmount = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 5px 10px;
    color: #2c3034;
`;

const ModalPaymentTitle = styled.div`
    font-size: 18px;
    text-align: center;
    padding: 5px 10px;
    color: #2c3034;
`;

const ModalPaymentSubtitle = styled.div`
    font-size: 14px;
    text-align: center;
    color: #2c3034;
    padding: 0 10px;
    margin-bottom: 40px;
`;

const ModalCloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 22px;
    font-weight: bold;
    color: #2c3034;

    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const PaypalButtonContainer = styled.div`
    padding: 10px 32px;
`;

const ThanksContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
`;

const ThanksImage = styled.img`
    width: 100px;
    height: auto;
`;

const ThanksTitle = styled.div`
    font-size: 32px;
    font-weight: bold;
    color: #222;
    margin-top: 10px;
    text-align: center;
`;

const ThanksText = styled.div`
    font-size: 16px;
    text-align: center;
    padding: 5px 0 10px 0;
    color: #333;
`;

const Link = styled.a`
    color: ${(props) => props.theme.colors.highlight};
    text-decoration: none;

    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }

`;