// Import node_modules
import React from 'react';
import styled from "styled-components";

import GoogleAdSense from './../../Utils/GoogleAdsense';

// Import image
import Error from './../../Assets/Icons/Vote.png';

export class NotFound extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <Container>
                <Content>
                    <ImageContainer>
                        <Image src={Error} alt="Error"/>
                    </ImageContainer>
                </Content>
                <Content><Text>The page you are looking for can not be found or an error may have occured.</Text></Content>
                <Content><Text>Shall we go back to the homepage now?</Text></Content>
                <Content><PrimaryButton href="/">Homepage</PrimaryButton></Content>
                <AdContent>
                    <GoogleAdSense />
                </AdContent>
            </Container>
        )
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
`;

const Content = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const ImageContainer = styled.div`
    margin: 40px 0; 
`;

const Image = styled.img`
    width: 250px;
`;

const Text = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0;
`;

const PrimaryButton = styled.a`
    display: inline-block;
    padding: 16px 32px;
    margin: 10px 15px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.highlight};;
    color: white;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    &:hover{
        background-color: #fc6a3e;
        cursor: pointer;
    }
`;

const AdContent = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;
