// Import node_modules
import React, { useContext } from 'react';
import styled from "styled-components";
// Import components
import { Loading } from '../Loading.jsx';
import { Hero } from './Hero.jsx';
import { UsedBy } from './UsedBy';
import { Levels } from './Levels';
import { UserFriendly } from './UserFriendly';
import { Customize } from './Customize';
import { Topgg } from './Topgg';
import GoogleAdSense from './../../Utils/GoogleAdsense';

export class Homepage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }

    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>

                    <Content>
                        <Hero />
                    </Content>

                    {/* <UsedBy /> */}

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                    <Content>
                        <Levels />
                        <UserFriendly />
                        <Customize />
                    </Content>

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                    <Content>
                        <Topgg />
                    </Content>

                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const AdContent = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;