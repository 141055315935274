// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

export class DeveloperPortal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            developer: {
                isDeveloper: false,
                user: "",
                limit: 60
            },
            isTokenShown: false
        };
    }

    async componentDidMount(){
        if(!this.props.user.isLogged){
            return this.props.history.push('/404');
        };
        let developer = await fetch(`/json/developer/${this.props.user.id}`);
        if(developer.status !== 200){
            return this.setState({ isLoading: false });
        };
        developer = await developer.json();
        this.setState({
            isLoading: false,
            developer: developer
        });
    }

    handleSumbit = async () => {
        let query = await fetch(`/json/developer/update/${this.props.user.id}`);
        if(query.status !== 200){
            return;
        };

        console.log(query);

        query = await query.json();
        console.log(query);
        this.setState({developer: query});
    }

    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            );
        }else{
            return (
                <Container>

                    <Content>
                        <Title>Developer portal</Title>
                        <Subtitle>You will need a token to access endpoints within the API such as guild leaderboards, rewards and members.</Subtitle>
                        <Subtitle>API documentation can be found <Link href="https://litochee.gitbook.io/amaribot/">here</Link>.</Subtitle>
                    </Content>
                    {(this.state.developer.isDeveloper === false || !this.state.developer.isDeveloper ) ? (
                        <Content>
                            <NoAuthContainer>
                                <Subtitle>You currently dont have access to this API, please fill out this form for access token <Link href="https://forms.gle/TEZ3YbbMPMEWYuuMA">click here.</Link></Subtitle>
                            </NoAuthContainer>
                        </Content>
                    ) : (
                        <Content>
                            <AuthContainer>
                                <TopDataContainer>
                                    <DataBlockContainer>
                                        <DataContainer>
                                            <DataTitle>User:</DataTitle>
                                            <DataBlock>{this.state.developer.user}</DataBlock>
                                        </DataContainer>
                                    </DataBlockContainer>
                                    <DataBlockContainer>
                                        <DataContainer>
                                            <DataTitle>Rate limit:</DataTitle>
                                            <DataBlock>{this.state.developer.limit}</DataBlock>
                                        </DataContainer>
                                    </DataBlockContainer>
                                </TopDataContainer>
                                <TokenContainer>
                                    <TokenTitle>Token:</TokenTitle>
                                    {!this.state.isTokenShown ? (<Token onClick={() => this.setState({isTokenShown: true})}>Show token</Token>) : (
                                        <DataBlockContainer>
                                        <DataContainer>
                                            <DataBlock>{this.state.developer.token}</DataBlock>
                                        </DataContainer>
                                    </DataBlockContainer>
                                    )}
                                </TokenContainer>

                                {this.state.developer.refresh > Date.now() ? (
                                    <NoRegenButton>Regen on cooldown</NoRegenButton>
                                ) : (<RegenButton onClick={this.handleSumbit}>Regen token</RegenButton>)}
                            </AuthContainer>
                        </Content>
                    )}

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            );
        }
    }
};

const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 12%;
    width: 76%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }

`;

const AdContent = styled.div`
    max-width: 1920px;
    margin: 20px 12%;
    width: 76%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;


const Title = styled.div`
    display: flex;
    color: ${(props) => props.theme.colors.font};
    padding: 10px 0;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
`;

const Subtitle = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: 18px;
    font-weight: 500;
`;

const Link = styled.a`
    color: ${(props) => props.theme.colors.highlight};
    text-decoration: none;

    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`;

const NoAuthContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.secondary};
    margin-top: 30px;
    padding: 15px 12px;
    border-radius: 8px;
`;


const AuthContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.secondary};
    margin-top: 30px;
    padding: 15px 12px;
    border-radius: 8px;
    flex-direction: column;
`;

const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.font};
    flex: 1;
`;

const DataTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`;

const DataBlockContainer = styled.div`
    display: flex;
    flex: 1;
    user-select: text;
`;
const DataBlock = styled.div`
    display: block;
    background-color: ${(props) => props.theme.colors.light};
    max-width: min-content;
    padding: 8px 15px 8px 10px;
    margin: 10px 0;
    border-radius: 4px;
    overflow-wrap: break-word;
    word-wrap: break-word;
`;

const TopDataContainer = styled.div`
    display: flex;
`;

const TokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.font};
`;

const TokenTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`;
const Token = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-weight: 500;
    margin: 5px 0;

    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        text-decoration: underline;
        cursor: pointer;
    }
`;

const RegenButton = styled.div`
    display: inline-block;
    padding: 12px 24px;
    margin: 45px 3px 0 3px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.highlight};;
    color: white;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    max-width: fit-content;
    &:hover{
        background-color: #fc6a3e;
        cursor: pointer;
    }
`;

const NoRegenButton = styled.div`
    display: inline-block;
    padding: 12px 24px;
    margin: 45px 3px 0 3px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.highlight};;
    color: white;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    max-width: fit-content;
    &:hover{
        background-color: #fc6a3e;
        cursor: no-drop;
    }
`;