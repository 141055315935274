// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import images
import CustomizePicture from './../../Assets/Images/customize.png';

export class Customize extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {logo: 0};
    }

    render() {
        return (
            <Container>
                <CustomizeTextContainer>
                    <CustomizeTitle>
                        Fully customizable
                    </CustomizeTitle>
                    <CustomizeDescription>
                        Amari takes customization to the next level, by letting you customize everything from announcements, down to the rank cards.
                    </CustomizeDescription>
                </CustomizeTextContainer>
                <CustomizeImageContainer>
                    <CustomizeImage src={CustomizePicture} alt="" />
                </CustomizeImageContainer>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    margin: 80px 5%;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: ${(props) => props.theme.colors.font};
    
    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
    }
`;

const CustomizeImageContainer = styled.div`
    flex: 1;
    text-align: center;

    @media (max-width: 768px) {
        order: 2;
        margin-bottom: 40px;
    }
`;

const CustomizeImage = styled.img`
    width: 92%;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.7));
    transition: 0.5s;
    border-radius: 12px;
    &:hover{
        transition: 0.5s;
        transform: scale(1.025);
    }
`;

const CustomizeHover = styled.a`
    color: ${(props) => props.theme.colors.highlight};
    text-decoration: none;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`

const CustomizeTextContainer = styled.div`
    flex: 1;
    font-family: 'Montserrat';
    
    @media (max-width: 768px) {
        margin-bottom: 40px;
        width: 92%;
    }
`;

const CustomizeTitle = styled.h1`
    color: ${(props) => props.theme.colors.highlight};
`;

const CustomizeDescription = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: ${(props) => props.theme.typography.l.fontSize};
`