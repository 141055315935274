// Import node_modules
import React from 'react';
import styled from "styled-components";
// Import components
import { BsFillCaretDownFill } from 'react-icons/bs';

const items = [
    {
        name: "Invite",
        link: "https://amaribot.com/invite"
    },
    {
        name: "Commands",
        link: "/commands"
    },
    {
        name: "About",
        children: [
            {
                name: "Docs",
                link: "https://litochee.gitbook.io/amaribot"
            },
            {
                name: "FAQ",
                link: "/faq"
            },
            {
                name: "Staff Team",
                link: "/staff"
            },
            {
                name: "XP Calculator",
                link: "/calculator"
            },
            {
                name: "XP Table",
                link: "/table"
            }
        ]
    },
    {
        name: "Donate",
        link: "/donate"
    },
    {
        name: "Community",
        link: "https://amaribot.com/support"
    }
];

export class Options extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        return (
            <Container>
                <NavbarTitle href="/">AmariBot</NavbarTitle>
                {items && items.map((item, i) => {
                    if(item.children){
                        return (
                            <NavbarDropdownOption key={i}>
                                <NavbarOptionDropdown>
                                    {item.name} <BsFillCaretDownFill />
                                </NavbarOptionDropdown>
                                <NavbarDropdown>
                                {item.children.map((child, x) => {
                                    return (
                                        <DropdownOption href={child.link} key={x}>
                                            {child.name}
                                        </DropdownOption>
                                    )
                                })}
                                </NavbarDropdown>
                            </NavbarDropdownOption>
                        )
                    }
                    return (
                        <NavbarOption key={i} href={item.link}>{item.name}</NavbarOption>
                    )
                })}
            </Container>
        )
    }
};


const Container = styled.div`
    flex: 1;
`;

const NavbarTitle = styled.a`
    display: inline-block;
    font-weight: bold;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    padding: 21px 24px;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }
`;

const NavbarOption = styled.a`
    display: inline-block;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    padding: 21px 24px;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }
`;


const NavbarOptionDropdown = styled.div`
    display: inline-flex;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    padding: 21px 24px;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
    align-items: center;

    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }
`;

const DropdownOption = styled.a`
    display: block;
    color: ${(props) => props.theme.colors.font};
    padding: 12px 24px 12px 16px;
    text-decoration: none;
    z-index: 99;
    
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

const NavbarDropdown = styled.div`
    display: none;
    position: absolute;
    text-align: left;
    background-color: ${(props) => props.theme.colors.secondary};
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    z-index: 99;
`;

const NavbarDropdownOption = styled.div`
    display: inline-block;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
    z-index: 99;

    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }

    &:hover ${NavbarDropdown}{
        display: block;
    }
`;
