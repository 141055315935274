// Import node_modules
import React from 'react';
import styled from "styled-components";
import { Doughnut } from 'react-chartjs-2';

export class DoughnutChart extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }


    render() {
        return (
            <Container>
            </Container>
        )
    }
};


const Container = styled.div`
    
`;