// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';
import { Policy } from './Policy.jsx';
import { Modal } from './Modal.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

// Import images
import Love from './../../Assets/Icons/Love.png';
import Bullet from './../../Assets/Icons/Bullet.png';

let perks = [
    "Configure EXP for global, channel or role multiplier for messages",
    "Configure the cooldown for EXP gain",
    "Unlock voice EXP feature",
    "Configure EXP and Cooldown for voice EXP",
    "Customise your rank card color, background and opacity"
]

export class Donate extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            gift: false,
            giftUser: "",
            amount: 5,
            id: "",
            processPayment: false,
            invalidUser: false,
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }


    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>
                    <Content>
                        <Title>Donate</Title>
                    </Content>
                    <DonationContent>
                        <DonationContainer>
                            <Header>
                                <HeaderImageContainer>
                                    <HeaderImage src={Love} alt="" />
                                </HeaderImageContainer>
                                <HeaderText>
                                    Thank you for using AmariBot! If you enjoy AmariBot please consider donating. All donations go towards keeping the bot hosted, development and content updates.
                                </HeaderText>
                            </Header>

                            <PremiumContainer>
                                <PremiumTitle>Premium Perks</PremiumTitle>
                                <PremiumSubTitle>Premium Perks are currently user based.</PremiumSubTitle>
                                {perks && perks.map((perk, i) => (
                                    <PremiumTextContainer key={i}>
                                        <PremiumImage src={Bullet} alt=""/>
                                        <PremiumText>{perk}</PremiumText>
                                    </PremiumTextContainer>
                                ))}
                            </PremiumContainer>

                            <DonationAmountContainer>
                                <DonationAmountBlock>
                                    <DonationSymbol>$</DonationSymbol>
                                    <DonationAmount value={this.state.amount} type="number" onChange={(event) => {
                                        if(parseInt(event.target.value) < 1){
                                            return;
                                        };
                                        this.setState({amount: parseInt(event.target.value)});
                                    }} />
                                </DonationAmountBlock>
                                
                                {this.state.amount < 5 ? (
                                    <ErrorText>
                                        Premium perks are only available for donations over $5
                                    </ErrorText>
                                ) : null}

                                {this.state.gift === false ? (
                                    <GiftContainer>
                                        <GiftCheckout type="checkbox" onChange={() => {
                                            this.setState({gift: true});
                                        }} />
                                        <GiftTitle>Purchase as gift</GiftTitle>
                                    </GiftContainer>
                                ) : <GiftInputContainer>
                                        <GiftTitle>
                                            User ID:
                                        </GiftTitle>
                                        <HowToFindID href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID" target="_blank">How to find ID</HowToFindID>
                                        <GiftUserID value={this.state.id} type="number" onChange={(event) => {
                                            if(parseInt(event.target.value) < 1){
                                                return;
                                            };
                                            this.setState({id: event.target.value});
                                        }} />

                                        {this.state.invalidUser === true ? (
                                            <ErrorText>
                                                Please enter a valid user ID
                                            </ErrorText>
                                        ) : null}

                                    </GiftInputContainer>}
                                
                                {this.props.user.isLogged === false ? (
                                    <DonationButton href="/login?redirect=/donate">Make Payment</DonationButton>
                                ) : (
                                    <DonationButton onClick={async() => {
                                        let validUser;
                                        if(this.state.gift === true && this.state.id.length < 17){
                                            return this.setState({invalidUser: true});
                                        };
                                        if(this.state.gift === true){
                                            let user = await fetch(`/discord/users/${this.state.id}`, {
                                                method: "GET",
                                                headers: { Authorization: `Bearer ${this.props.user.id}` }
                                            });
                                            if(user.status !== 200){
                                                return this.setState({invalidUser: true});
                                            };
                                            validUser = await user.json();
                                        };
    
                                        this.setState({processPayment: true, userObj: validUser});
                                    }}>Make Payment</DonationButton>
                                )}
                            </DonationAmountContainer>

                            <Policy />
                        </DonationContainer>
                    </DonationContent>
                    {this.state.processPayment === true ? <Modal parentObject={this} user={this.props.user} /> : null}

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
`;

const Content = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const AdContent = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const DonationContent = styled.div`
    max-width: 1920px;
    margin: 0 20%;
    width: 60%;
    height: auto;
    display: flex;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const DonationContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 20px 30px;
    background-color: ${(props) => props.theme.colors.secondary};
    border-radius: 12px;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
`;

const Header = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.primary};
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    margin: 15px 0 20px 0;
    border-radius: 12px;
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.5));
`;

const HeaderImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HeaderImage = styled.img`
    min-width: 50px;
    max-width: 50px;
    height: auto;
    padding: 0 0 0 15px;
`;

const HeaderText = styled.div`
    padding: 0 15px;
    color: ${(props) => props.theme.colors.font};
    font-size: 18px;
    font-weight: 400;
    flex: 1;
`;

const PremiumContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.primary};
    flex-direction: column;
    padding: 15px;
    border-radius: 12px;
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.5));
`;

const PremiumTitle = styled.div`
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.highlight};
    display: block;
`;

const PremiumSubTitle = styled.div`
    text-align: left;
    font-size: 18px;
    padding: 5px 0;
    font-weight: 600;
    color: ${(props) => props.theme.colors.font};
    display: block;
`;

const PremiumTextContainer = styled.div`
    display: flex;
    padding: 5px 0;
    padding-left: 8px;
`;

const PremiumImage = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding-right: 5px;
`;

const PremiumText= styled.div`
    display: flex;
    flex: 1;
    color: ${(props) => props.theme.colors.font}bb;
    font-size: 18px;
    font-weight: 500;
`;

const DonationAmountContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
`;

const DonationAmountBlock = styled.div`
    display: flex;
`;

const DonationAmount = styled.input`
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 0 8px 8px 0;
    margin-right: 20px;
    min-width: 250px;
    filter: drop-shadow(0 0 1px rgba(0,0,0,0.5));
    border-left: none;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.primary};
    }

    @media (max-width: 768px) {
        min-width: 100px;
    }

    @media (max-width: 400px) {
        min-width: 50px;
    }
`;

const DonationButton = styled.a`
    background-color: ${(props) => props.theme.colors.highlight}ea;
    font-size: 18px;
    margin: 12px 0;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 12px 15px -15px rgb(0 0 0 / 80%);
    color: ${(props) => props.theme.colors.font};
    font-weight: 600;
    text-decoration: none;
    
    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const DonationSymbol = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.secondaryFont};
    padding: 0 15px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    filter: drop-shadow(0 0 1px rgba(0,0,0,0.5));
    border-radius: 8px 0 0 8px;
    border-right: none;
    font-size: 20px;
`;

const GiftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const GiftCheckout = styled.input`
    width: 22px;
    height: 22px;
    display: inline-block;
`;

const GiftTitle = styled.div`
    color: ${(props) => props.theme.colors.secondaryFont};
    display: inline-block;
    font-size: 24px;
    text-align: center;
`;

const ErrorText = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.secondaryFont};
    margin-top: 10px;
`;

const GiftInputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
`;

const GiftUserID = styled.input`
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    min-width: 275px;
    width: auto;
    filter: drop-shadow(0 0 1px rgba(0,0,0,0.5));
    border-left: none;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }

    @media (max-width: 768px) {
        min-width: 100px;
    }

    @media (max-width: 400px) {
        min-width: 50px;
    }
`;

const HowToFindID = styled.a`
    color: ${(props) => props.theme.colors.highlight};
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }
`;