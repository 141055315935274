// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';

export class Refund extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }

    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>

                    <Content>
                        <Title>Refund Policy</Title>
                    </Content>

                    <Content>
                        <SubTitle>PayPal refunds</SubTitle>
                        <TextBlock>Refunds are only issued for specific situations. Valid reasons for refund but not limited to are; not receiving your premium perks and when a user does not receive premium perks when gifted. You not reading the permissions needed for specific commands is not a valid reason for receiving a refund.</TextBlock>

                        <SecondSubTitle>How to request a refund</SecondSubTitle>
                        <TextBlock>
                            Refunds can be requested by sending an email to <Link href="mailto:help@amaribot.com">help@amaribot.com</Link> or join our <Link>support server</Link> and contact our staff team.
                        </TextBlock>
                        <TextBlock>
                            If you threaten to chargeback, we reserve the right to ban/blacklist you from using AmariBot.
                        </TextBlock>
                    </Content>
                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    height: 80vh;
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 15%;
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-top: 20px;
`;

const SubTitle = styled.div`
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 22px;
    font-weight: 600;
`;

const SecondSubTitle = styled.div`
    color: ${(props) => props.theme.colors.font};
    margin-top: 30px;
    padding: 15px 0;
    font-size: 22px;
    font-weight: 600;
`;

const TextBlock = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: 17px;
    font-weight: 400;
`;

const Link = styled.a`
    color: ${(props) => props.theme.colors.font};
    text-decoration: none;

    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
`;