// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import image
import Logo from './../Assets/Icons/Logo.png';

export class Loading extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <IsLoadingContainer>
                <LoadingImage src={Logo} alt="" />
            </IsLoadingContainer>
            );
    }
};

const IsLoadingContainer = styled.div`
    height: 89vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingImage = styled.img`
    width: 100px;
    height: 100px;
    animation:spin 4s linear infinite;

    @keyframes spin {
        0%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
        10%   {-webkit-transform: rotate(40deg); transform:rotate(40deg);}
        20%   {-webkit-transform: rotate(80deg); transform:rotate(80deg);}
        30%   {-webkit-transform: rotate(120deg); transform:rotate(120deg);}
        40%   {-webkit-transform: rotate(160deg); transform:rotate(160deg);}
        50%   {-webkit-transform: rotate(200deg); transform:rotate(200deg);}
        60%   {-webkit-transform: rotate(240deg); transform:rotate(240deg);}
        70%   {-webkit-transform: rotate(280deg); transform:rotate(280deg);}
        80%   {-webkit-transform: rotate(320deg); transform:rotate(320deg);}
        90%   {-webkit-transform: rotate(360deg); transform:rotate(360deg);}
        100%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
    }

    @-webkit-keyframes spin {
        0%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
        10%   {-webkit-transform: rotate(40deg); transform:rotate(40deg);}
        20%   {-webkit-transform: rotate(80deg); transform:rotate(80deg);}
        30%   {-webkit-transform: rotate(120deg); transform:rotate(120deg);}
        40%   {-webkit-transform: rotate(160deg); transform:rotate(160deg);}
        50%   {-webkit-transform: rotate(200deg); transform:rotate(200deg);}
        60%   {-webkit-transform: rotate(240deg); transform:rotate(240deg);}
        70%   {-webkit-transform: rotate(280deg); transform:rotate(280deg);}
        80%   {-webkit-transform: rotate(320deg); transform:rotate(320deg);}
        90%   {-webkit-transform: rotate(360deg); transform:rotate(360deg);}
        100%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
    }

    @-moz-keyframes spin {
        0%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
        10%   {-webkit-transform: rotate(40deg); transform:rotate(40deg);}
        20%   {-webkit-transform: rotate(80deg); transform:rotate(80deg);}
        30%   {-webkit-transform: rotate(120deg); transform:rotate(120deg);}
        40%   {-webkit-transform: rotate(160deg); transform:rotate(160deg);}
        50%   {-webkit-transform: rotate(200deg); transform:rotate(200deg);}
        60%   {-webkit-transform: rotate(240deg); transform:rotate(240deg);}
        70%   {-webkit-transform: rotate(280deg); transform:rotate(280deg);}
        80%   {-webkit-transform: rotate(320deg); transform:rotate(320deg);}
        90%   {-webkit-transform: rotate(360deg); transform:rotate(360deg);}
        100%   {-webkit-transform: rotate(0deg); transform:rotate(0deg);}
    }
`;