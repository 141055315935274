// Import node_modules
import React from 'react';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';

// Import components

export class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {isLogged: false};
    }


    render() {
        if(this.state.isLogged){
            return (
                <Container>
                </Container>
            );
        }else{
            return (
                <Container>
                    <LoginContainer>
                        <LoginButton href={`/login?redirect=${window.location.pathname}`}>
                            Login
                        </LoginButton>
                    </LoginContainer>
                </Container>
            );
        };
    };
};


const Container = styled.div`
    display: flex;
`;

const LoginContainer = styled.div`
    display: inline-block;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    padding: 21px 0;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
`;

const LoginButton = styled.a`
    border: 2px solid ${(props) => props.theme.colors.highlight};
    padding: 8px 24px;
    border-radius: 8px;
    margin-right: 8px;
    font-weight: bold;
    text-decoration: none;
    color: ${(props) => props.theme.colors.font};

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;