// Import node_modules
import React from 'react';
import styled from "styled-components";
import AvatarIcon from 'react-avatar';
const staff = [
    '439223656200273932',
    '242760305623433219',
    '395526710101278721',
    '267331268243488769',
    '249955383001481216',
    '222239619897360385',
    '107510319315697664',
    '235792008763932672',
    '282075857194057729',
    '345686279880572929',
    '192719510510239746'
];
export class Avatar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            user: {
                isLogged: false
            },
            hideNav: false
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
    }
    
    resize() {
        this.setState({hideNav: window.innerWidth <= 1000});
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        return (
            <Container>
                <NavbarDropdownOption>
                    <NavbarOptionDropdown>
                        <AvatarIcon size={40} name={this.props.user.username} round={true} src={this.props.user.avatar} />
                        {this.state.hideNav === true ? null : <Username>{this.props.user.username}#{this.props.user.tag}</Username>}
                    </NavbarOptionDropdown>
                    <NavbarDropdown>
                        {/* <div>
                            {!this.props.user.banner ? (
                                <BannerContainer style={{backgroundColor: this.props.user.banner_color}}/>
                            ) : (
                                <BannerContainer src={this.props.user.banner} />
                            )}
                        </div> */}
                        {/* <DropdownOption href="/logout">
                            Dashboard
                        </DropdownOption> */}
                        <DropdownOption href="/developer">
                            Developer portal
                        </DropdownOption>
                        {staff.includes(this.props.user.id) ? (
                            <DropdownOption href='/staff/edit'>
                                Staff Dashboard
                            </DropdownOption>
                        ) : null}
                        <DropdownOption href="/logout">
                            Logout
                        </DropdownOption>
                    </NavbarDropdown>
                </NavbarDropdownOption>
            </Container>
        )
    }
};


const Username = styled.div`
    padding: 0 6px;
`;

const BannerContainer = styled.div`
    width: 200px;
    height: 80px;
    border: none;
`;

const NavbarOptionDropdown = styled.div`
    display: inline-flex;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
    align-items: center;
`;

const DropdownOption = styled.a`
    display: block;
    color: ${(props) => props.theme.colors.font};
    padding: 12px 24px 12px 16px;
    text-decoration: none;
    z-index: 99;
    
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

const NavbarDropdown = styled.div`
    display: none;
    position: absolute;
    text-align: left;
    background-color: ${(props) => props.theme.colors.light};
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    z-index: 99;
    right: 12px;
    margin-top: 5px;
`;

const NavbarDropdownOption = styled.div`
    display: inline-block;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    text-decoration: none;
    z-index: 99;

    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }

    &:hover ${NavbarDropdown}{
        display: block;
    }
`;


const Container = styled.div`
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }

    &:hover ${NavbarDropdown}{
        display: block;
    }
`;