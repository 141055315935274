// Import node_modules
import React from 'react';
import styled from "styled-components";
// Import components
import { AiOutlineMenu } from 'react-icons/ai';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { Login } from './Login';
import { Avatar } from './Avatar';

const items = [
    {
        name: "Invite",
        link: "https://amaribot.com/invite"
    },
    {
        name: "Commands",
        link: "/commands"
    },
    {
        name: "About",
        children: [
            {
                name: "Docs",
                link: "https://litochee.gitbook.io/amaribot"
            },
            {
                name: "FAQ",
                link: "/faq"
            },
            {
                name: "Staff Team",
                link: "/staff"
            },
            {
                name: "XP Calculator",
                link: "/calculator"
            },
            {
                name: "XP Table",
                link: "/table"
            }
        ]
    },
    {
        name: "Donate",
        link: "/donate"
    },
    {
        name: "Community",
        link: "https://amaribot.com/support"
    }
];

export class Mobile extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {menu: false};
    }


    render() {
        return (
            <Container>
                <NavbarOptions>
                    <NavbarTitle href="/">AmariBot</NavbarTitle>
                </NavbarOptions>
                {this.props.user.isLogged === true ? <Avatar user={this.props.user} /> : <Login />}
                <MobileMenu onClick={() => {
                    let newValue = !this.state.menu;
                    this.setState({menu: newValue});
                }}>
                    <AiOutlineMenu style={{'fontSize': '20px'}} />
                </MobileMenu>
                <MobileMenuOptions style={this.state.menu ? {display: 'block'} : {}}>
                    {items && items.map((item, i) => {
                        if(item.children){
                            return (
                                <NavbarOptionDropdown key={i}>
                                    <DropdownSelector>
                                        {item.name} <BsFillCaretDownFill />
                                    </DropdownSelector>
                                    <DropdownOptions>
                                    {item.children.map((x, y) => (
                                        <DropdownOption key={y} href={x.link}>{x.name}</DropdownOption>
                                    ))}
                                    </DropdownOptions>
                                </NavbarOptionDropdown>
                            )
                        };
                        return (
                            <NavbarOption key={i} href={item.link}>{item.name}</NavbarOption>
                        )
                    })}
                </MobileMenuOptions>
            </Container>
        )
    }
};


const Container = styled.div`
    display: flex;
    width: 100%;
`;

const NavbarOptions = styled.div`
    flex: 1;
    display: flex;
`;

const NavbarTitle = styled.a`
    font-weight: bold;
    color: ${(props) => props.theme.colors.font};
    padding: 21px 24px;
    text-decoration: none;
    font-size: 17px;
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }
`;

const MobileMenu = styled.div`
    display: inline-block;
    font-weight: bold;
    color: ${(props) => props.theme.colors.font};
    text-align: center;
    padding: 21px 24px;
    font-size: 17px;
    text-decoration: none;
    &:hover{
        color: ${(props) => props.theme.colors.highlight};
        background-color: ${(props) => props.theme.colors.secondary};
        cursor: pointer;
    }
`;

const MobileMenuOptions = styled.div`
    display: none;
    position: absolute;
    width: 100vw;
    margin-top: 62px;
    z-index: 99;
    background-color: ${(props) => props.theme.colors.secondary};
    text-decoration: none;
    height: 100vh;
    overflow-x: hidden;
`

const NavbarOption = styled.a`
    display: block;
    width: 100%;
    padding: 21px 0 21px 24px;
    color: ${(props) => props.theme.colors.secondaryFont};
    font-size: 18px;
    text-decoration: none;
    &:hover{
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.highlight};
    }
`;

const DropdownOption = styled.a`
    display: block;
    width: 100%;
    padding: 21px 0 21px 24px;
    color: ${(props) => props.theme.colors.secondaryFont};
    font-size: 18px;
    text-decoration: none;
    &:hover{
        background-color: ${(props) => props.theme.colors.secondary};
        color: ${(props) => props.theme.colors.highlight};
    }
`;

const DropdownOptions = styled.div`
    display: none;
`;

const DropdownSelector = styled.div`
    display: inline-flex;
    padding: 21px 0 21px 24px;
`;

const NavbarOptionDropdown = styled.div`
    display: inline-block;
    width: 100%;
    color: ${(props) => props.theme.colors.secondaryFont};
    font-size: 18px;
    text-decoration: none;
    &:hover{
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.highlight};
    }

    &:hover ${DropdownOptions}{
        display: block;
    }
`;