import React from 'react';
import AdSense from 'react-adsense';

export default function App(props) {
    
    return (
        <AdSense.Google
            client='ca-pub-7098412664813213'
            slot='8031002336'
            style={{ display: 'block', overflow: 'hidden' }}
            format='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
        />
    )
}
