export default class User{
    constructor(user){
        this.id = user.id || false,
        this.username = user.username || false,
        this.tag = user.discriminator || false,
        this.avatar = !user.avatar ? false : user.avatar.startsWith('a_') ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.gif` : `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`,
        this.banner = user.banner || false,
        this.banner_color = user.banner_color || false,
        this.color = user.accent_color || false,
        this.isLogged = user.isLogged
    };
};