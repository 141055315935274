// Import node_modules
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from "styled-components"

// Import react components
import { Calculator } from './Components/Calculator/CalculatorPage';
import { Commands } from './Components/Commands/CommandsPage';
import { Coowner } from './Components/Coowner/Coowner';
import { ApproveCoowner } from './Components/Coowner/Approve';
import { DeveloperPortal } from './Components/Developer/DeveloperPage';
import { Donate } from './Components/Donate/DonatePage';
import { Faq } from './Components/Faq/FaqPage';
import { Homepage } from './Components/Homepage/Homepage';
import { Leaderboard } from './Components/Leaderboard/LeaderboardPage';
import { Refund } from './Components/Refund/RefundPage';
import { Staff } from './Components/Staff/StaffPage';
import { StaffEdit } from './Components/StaffDashboard/StaffDashboard';
import { Statistics } from './Components/Statistics/Statistics';
import { Status } from './Components/Status/Status';
import { Table } from './Components/Table/TablePage';
import { WeeklyLeaderboard } from './Components/Leaderboard/WeeklyLeaderboard';
import { Footer } from './Components/Navbar/Footer';
import { Navbar } from './Components/Navbar/Navbar';
import { Loading } from './Components/Loading';
import { NotFound } from './Components/Errors/404';
import User from './Stores/User';

// Import styles
import { darkTheme } from './Utils/Style';
import { lightTheme } from './Utils/Style';

// Import classes

export class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: 'dark',
            user: {
                isLogged: false
            },
            isLoaded: false,
            date: Date.now()
        };
    }

    async componentDidMount(){
        let theme = !window.localStorage.getItem('theme') ? "dark" : window.localStorage.getItem('theme');
        let user = await fetch('/user');
        user = await user.json();
        let userObj = new User(user);
        this.setState({theme: theme, user: userObj, isLoaded: true});
    }

    render() {
        let theme = this.state.theme === "dark" ? darkTheme : lightTheme;
        if(this.state.isLoaded === true){
            return (
                <ThemeProvider theme={theme}>
                        <AppBody />
                        
                        <div class="snowflakes" aria-hidden="true">
                            <div class="snowflake">❅</div>
                            <div class="snowflake">❅</div>
                            <div class="snowflake">❆</div>
                            <div class="snowflake">❄</div>
                            <div class="snowflake">❅</div>
                            <div class="snowflake">❆</div>
                            <div class="snowflake">❄</div>
                            <div class="snowflake">❅</div>
                            <div class="snowflake">❆</div>
                            <div class="snowflake">❄</div>
                            <Navbar user={this.state.user}/>
                                <Router>
                                    <Switch>
                                        <Route exact path="/" render={(props) => <Homepage {...props} user={this.state.user} />} />
                                        <Route exact path="/application/coowner" render={(props) => <Coowner {...props} user={this.state.user} />} />
                                        <Route exact path="/approve/coowner" render={(props) => <ApproveCoowner {...props} user={this.state.user} />} />
                                        <Route exact path="/calculator" render={(props) => <Calculator {...props} user={this.state.user} />} />
                                        <Route exact path="/commands" render={(props) => <Commands {...props} user={this.state.user} />} />
                                        <Route exact path="/developer" render={(props) => <DeveloperPortal {...props} user={this.state.user} />} />
                                        <Route exact path="/donate" render={(props) => <Donate {...props} user={this.state.user} />} />
                                        <Route exact path="/faq" render={(props) => <Faq {...props} user={this.state.user} />} />
                                        <Route exact path="/leaderboard/:id" render={(props) => <Leaderboard {...props} user={this.state.user} />} />
                                        <Route exact path="/leaderboard/weekly/:id" render={(props) => <WeeklyLeaderboard {...props} user={this.state.user} />} />
                                        <Route exact path="/refund" render={(props) => <Refund {...props} user={this.state.user} />} />
                                        <Route exact path="/staff/edit" render={(props) => <StaffEdit {...props} user={this.state.user} />} />
                                        <Route exact path="/staff" render={(props) => <Staff {...props} user={this.state.user} />} />
                                        <Route exact path="/statistics" render={(props) => <Statistics {...props} user={this.state.user} />} />
                                        <Route exact path="/status" render={(props) => <Status {...props} user={this.state.user} />} />
                                        <Route exact path="/table" render={(props) => <Table {...props} user={this.state.user} />} />
                                        <Route exact path="/404" render={(props) => <NotFound {...props} user={this.state.user} />} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </Router>
                            < Footer routerParent={this} />
                        </div>
                </ThemeProvider>
            )
        }else{
            return (
                <ThemeProvider theme={theme}>
                        <AppBody />
                        <Navbar user={this.state.user}/>
                        <Router>
                            <Route exact path="/" component={Loading} />
                        </Router>
                        < Footer routerParent={this} />
                </ThemeProvider>
            )
        }
    }
};

const AppBody = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: 'Montserrat';
        font-weight: 500;
        user-select: none;
        background-color: ${(props) => props.theme.colors.primary};
        overflow-x: hidden;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.colors.light};
    }

    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.secondary};
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.colors.secondary}ea;
    }
`;
