export const darkTheme = {
    fonts: {
        body: `"sans-serif"`,
    },
    colors: {
        primary: "#282c34", // Medium
        secondary: "#21252b", // Dark
        light: "#333842", // Light
        highlight: "#f67852",
        font: "#ffffff",
        secondaryFont: "#99aab5",
    },
    typography: {
        xs: {
            fontSize: "12px",
            lineHeight: 1.7,
        },
        s: {
            fontSize: "14px",
            lineHeight: 1.6,
        },
        m: {
            fontSize: "16px",
            lineHeight: 1.6,
        },
        l: {
            fontSize: "18px",
            lineHeight: 1.5,
        },
        xl: {
            fontSize: "20px",
            lineHeight: 1.4,
        },
        "2xl": {
            fontSize: "24px",
            lineHeight: 1.3,
        },
        "3xl": {
            fontSize: "30px",
            lineHeight: 1.2,
        },
        "4xl": {
            fontSize: "36px",
            lineHeight: 1.2,
        },
        "5xl": {
            fontSize: "48px",
            lineHeight: 1.1,
        },
    }
}


export const lightTheme = {
    fonts: {
        body: `"sans-serif"`,
    },
    colors: {
        primary: "#fafafa", // Medium
        secondary: "#eaeaeb", // Dark
        light: "#f1f1f1", // Light
        highlight: "#f67852",
        font: "#000000",
        secondaryFont: "#99aab5",
    },
    typography: {
        xs: {
            fontSize: "12px",
            lineHeight: 1.7,
        },
        s: {
            fontSize: "14px",
            lineHeight: 1.6,
        },
        m: {
            fontSize: "16px",
            lineHeight: 1.6,
        },
        l: {
            fontSize: "18px",
            lineHeight: 1.5,
        },
        xl: {
            fontSize: "20px",
            lineHeight: 1.4,
        },
        "2xl": {
            fontSize: "24px",
            lineHeight: 1.3,
        },
        "3xl": {
            fontSize: "30px",
            lineHeight: 1.2,
        },
        "4xl": {
            fontSize: "36px",
            lineHeight: 1.2,
        },
        "5xl": {
            fontSize: "48px",
            lineHeight: 1.1,
        },
    }
};