// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import images
import LevelsImage from './../../Assets/Images/Levels.png';

export class Levels extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {logo: 0};
    }

    render() {
        return (
            <Container>
                <LevelTextContainer>
                    <LevelTitle>
                        Level and XP
                    </LevelTitle>
                    <LevelDescription>
                    Amari's XP and leveling system rewards the most active members in your server! 
                    Reward your members by automatically assigning roles when they reach a certain level with access to exclusive channels and privileges.
                    </LevelDescription>
                </LevelTextContainer>
                <LevelImageContainer>
                    <LevelImage src={LevelsImage} alt="" />
                </LevelImageContainer>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    margin: 80px 5%;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: ${(props) => props.theme.colors.font};

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
    }
`;

const LevelImageContainer = styled.div`
    flex: 1;
    text-align: center;

    @media (max-width: 768px) {
        order: 2;
        margin-bottom: 40px;
    }
`;

const LevelImage = styled.img`
    width: 92%;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.7));
    transition: 0.5s;
    &:hover{
        transition: 0.5s;
        transform: scale(1.025);
    }
`;

const LevelTextContainer = styled.div`
    flex: 1;
    font-family: 'Montserrat';

    @media (max-width: 768px) {
        margin-bottom: 40px;
        width: 92%;
    }
`;

const LevelTitle = styled.h1`
    color: ${(props) => props.theme.colors.highlight};
`;

const LevelDescription = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: ${(props) => props.theme.typography.l.fontSize};
`