// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

// Import image
import Bullet from './../../Assets/Icons/Bullet.png';
import { FaMinus, FaPlus } from 'react-icons/fa';

export class Faq extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            faq: [
                {
                    title: "The bot won't answer me! What do I do?",
                    content: (
                        <FaqDescriptionContainer>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> It could be a permissions issue! Make sure the bot has the "Send Messages" and "Embed Links" permissions in that channel.
                            </FaqDescription>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" />
                                You may have changed the prefix and forgot about it! Ping 
                                <AmariTag>
                                    @AmariBot
                                    {/* <AmariProfileContainer>
                                        <AmariProfileColor />
                                        <AmariProfileImageContainer>
                                            <AmariProfileImage src="https://cdn.discordapp.com/avatars/339254240012664832/0cfec781df368dbce990d440d075a2d7.png" />
                                            <AmariStatus />
                                        </AmariProfileImageContainer>
                                        AmariBot Shit
                                    </AmariProfileContainer> */}
                                </AmariTag>for the current prefix in use
                            </FaqDescription>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> You may be typing the default prefix wrong! Make sure you're actually typing <Prefix>:?</Prefix> as the prefix.
                            </FaqDescription>
                        </FaqDescriptionContainer>
                    ),
                    active: false
                },
                {
                    title: "The bot is telling me I don't have permission, what gives?",
                    content: (
                        <FaqDescriptionContainer>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> You probably don't have the <Bold>AmariMod</Bold> role! Create a role called <Bold>AmariMod</Bold> (case sensitive) in your server and give it to yourself. It'll also work if you have the "Administrator" permission.
                            </FaqDescription>
                        </FaqDescriptionContainer>
                    ),
                    active: false
                },
                {
                    title: "How frequently do people gain EXP with AmariBot?",
                    content: (
                        <FaqDescriptionContainer>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> Default rate is 1 EXP per message, with a cooldown of 8 seconds! Customize these rates by becoming a donator. Find the level <Link href='/table'>table here</Link>.
                            </FaqDescription>
                        </FaqDescriptionContainer>
                    ),
                    active: false
                },
                {
                    title: "How do I make the bot give roles at a certain level?",
                    content: (
                        <FaqDescriptionContainer>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> With the rlevel command! Example: <Prefix>:?rlevel add 1 epic test</Prefix> would give members the role "epic test" once they reach level 1. Read through <Prefix>:?help rlevel</Prefix> for more.
                            </FaqDescription>
                        </FaqDescriptionContainer>
                    ),
                    active: false
                },
                {
                    title: "I configured rlevel correctly, but the bot isn't assigning roles!",
                    content: (
                        <FaqDescriptionContainer>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> In your server, check if the bot's role is <Underline>above</Underline> the roles it's trying to give. It <Underline>cannot</Underline> give roles that are above itself under any circumstance.
                            </FaqDescription>
                        </FaqDescriptionContainer>
                    ),
                    active: false
                },
                {
                    title: "Members are keeping their old roles after getting a new one. Can I change this?",
                    content: (
                        <FaqDescriptionContainer>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> Yes, you can! Use the <Prefix>:?rlevel replace</Prefix> command!
                            </FaqDescription>
                            <FaqDescription>
                                <FaqIcon src={Bullet} alt="" /> To set it back so roles accumulate, keeping all roles, use <Prefix>:?rlevel accumulate</Prefix> instead.
                            </FaqDescription>
                        </FaqDescriptionContainer>
                    ),

                    active: false
                },
            ]
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }


    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>
                    <FaqContent>
                        <FaqMainTitle>Frequently Asked Questions</FaqMainTitle>
                        {this.state.faq && this.state.faq.map((item, i) => (
                            item.active ? (
                                <div key={i}>
                                    <FaqContainerShadow onClick={() => {
                                        let newFaq = this.state.faq;
                                        newFaq[i].active = !newFaq[i].active;
                                        this.setState({faq: newFaq}); 
                                    }}>
                                        <FaqTitle>{item.title}</FaqTitle>
                                        <FaqButton>{item.active ? (<FaMinus />) : (<FaPlus />)}</FaqButton>
                                    </FaqContainerShadow>
                                    {item.content}
                                </div>
                            ) : (
                                <FaqContainer key={i} onClick={() => {
                                    let newFaq = this.state.faq;
                                    newFaq[i].active = !newFaq[i].active;
                                    this.setState({faq: newFaq}); 
                                }}>
                                    <FaqTitle>{item.title}</FaqTitle>
                                    <FaqButton>{item.active ? (<FaMinus />) : (<FaPlus />)}</FaqButton>
                                </FaqContainer>
                            )
                        ))}
                    </FaqContent>

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Content = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const AdContent = styled.div`
    width: 100%;
    height: auto;
    text-align: center;
`;

const FaqContent = styled.div`
max-width: 1920px;
margin: 0px 10%;
width: 80%;
height: auto;
display: flex;
flex-direction: column;
min-height: 88vh;

@media screen and (max-width: 1550px) {
    margin: 0 6%;
    width: 88%;
}

@media screen and (max-width: 1100px) {
    margin: 0 3%;
    width: 94%;
}

@media (max-width: 768px) {
    margin: 0px 2%;
    width: 96%;
}
`;

const FaqMainTitle = styled.h1`
    color: white;
    text-align: center;
    margin: 35px 0;
`;

const FaqContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.highlight}dd;
    border-radius: 12px;
    margin: 6px 0;
    &:hover{
        background-color: ${(props) => props.theme.colors.secondary}cc;
        cursor: pointer;
    }
`;

const FaqContainerShadow = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.highlight};
    border-radius: 12px 12px 0 0;
    margin: 6px 0 0 0;
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.5));

    &:hover{
        background-color: ${(props) => props.theme.colors.secondary}cc;
        cursor: pointer;
    }
`;

const FaqTitle = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 23px 9px 23px 15px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 17px;
    border-radius: 3px 3px 3px 3px;
    font-weight: 600;
`;

const FaqButton = styled.div`
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px 18px 23px 15px;
`;

const FaqDescriptionContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px 0 15px 15px;
    background-color: ${(props) => props.theme.colors.secondary};
    flex-direction: column;
`;

const FaqDescription = styled.div`
    display: inline-block;
    flex: 1;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    margin: 5px 0;
`;

const FaqIcon = styled.img`
    width: 20px;
    height: 20px;
    padding: 0 3px;
`;

const Prefix = styled.div`
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.highlight};
    margin: 0 4px;
    padding: 0 3px;
    font-family: 'Montserrat';
    display: inline-block;
`;

const Underline = styled.div`
    margin: 0 4px;
    padding: 0 3px;
    text-decoration: underline;
    display: inline-block;
`;

const Bold = styled.div`
    font-weight: bold;
    display: inline-block;
`;

const Link = styled.a`
    color: ${(props) => props.theme.colors.highlight}ea;
    text-decoration: none;
    display: inline-block;

    &:hover{
        cursor: pointer;
        text-decoration: underline;
        color: ${(props) => props.theme.colors.highlight};
    }
`;

const AmariTag = styled.div`
    margin: 0 4px;
    padding: 2px 5px 2px 3px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.highlight}cc;
    display: inline-block;

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const AmariProfileContainer = styled.div`
    position: absolute;
    z-index: 99;
    width: 300px;
    background-color: #18191c;
    border-radius: 8px;
    margin-top: 5px;
    margin-left: 95px;
`;

const AmariProfileColor = styled.div`
    width: 100%;
    height: 60px;
    background-color: #f9e4aa;
    border-radius: 8px 8px 0 0;
`;

const AmariProfileImageContainer = styled.div`
    position: absolute;
    margin-top: -40px;
    background-color: #18191c;
    border-radius: 50%;
`;

const AmariProfileImage = styled.img`
    width: 80px;
    height: 80px;
    border: 6px solid #18191c;
    border-radius: 50%;
`;

const AmariStatus = styled.div`
    width: 16px;
    height: 16px;
    background-color: #3ba55d;
    border-radius: 50%;
    border: 6px solid #18191c;
    position: absolute;
    margin-top: -35px;
    margin-left: 60px;
`;