// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';
import { LevelCalculator } from './Level.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

export class Calculator extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount(){
        this.setState({isLoading: false});
    }


    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>

                    <Content>
                        <Title>
                            Leveling Calculator
                        </Title>
                        <LevelCalculator />
                    </Content>
                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 88vh;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
`;

const AdContent = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;