// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components

// Import icons
import { FaMinus, FaPlus } from 'react-icons/fa';

export class LevelCalculator extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            userInput: {
                level: 1,
                start: 0,
                cooldown: 8,
                multiplier: 1,
                roles: [
                    {exp: 0, color: "#fae8a7"},
                    // {exp: 4, color: "#e26767"},
                    // {exp: 8, color: "#a7fac7"},
                    // {exp: 42, color: "#faa7f6"},
                    // {exp: 0, color: "#a7a8fa"}
                ],
                results: "1 minute 30 seconds",
                startlevel: false
            },
            results: {
                messages: "35",
                exp: "35",
                time: "4 minutes 40 seconds",
                realTime: ""
            }
        };
    }

    convertTime = (milliseconds) => {
        let roundTowardsZero = milliseconds > 0 ? Math.floor : Math.ceil;
        let years = roundTowardsZero(milliseconds / 31104000000),
        months = roundTowardsZero(milliseconds / 2592000000) % 12,
        days = roundTowardsZero(milliseconds / 86400000) % 30,
        hours = roundTowardsZero(milliseconds / 3600000) % 24,
        mins = roundTowardsZero(milliseconds / 60000) % 60,
        secs = roundTowardsZero(milliseconds / 1000) % 60;
        
        if(secs === 0){
            secs++;
        }
        let theyears = years > 0,
        themonths = months > 0,
        theDays = days > 0,
        theHours = hours > 0,
        theMinutes = mins > 0;
        let pattern =
        (!theyears ? "" : (themonths || theDays) ? "{years} years, " : "{years} years & ") +
        (!themonths ? "" : (theHours || theDays) ? "{months} months, " : "{months} months & ") +
        (!theDays ? "" : (theMinutes || theHours) ? "{days} days, " : "{days} days & ") +
        (!theHours ? "" : (theMinutes) ? "{hours} hours, " : "{hours} hours & ") +
        (!theMinutes ? "" : "{mins} minutes") +
        (" {secs} seconds");
        let finalTime = pattern
        .replace("{years}", years)
        .replace("{months}", months)
        .replace("{days}", days)
        .replace("{hours}", hours)
        .replace("{mins}", mins)
        .replace("{secs}", secs);
        return finalTime;
    }

    calculateResults = (data) => {
        let datalevel = isNaN(parseInt(data.level)) ? 1 : parseInt(data.level);
        let datastart = isNaN(parseInt(data.start)) ? 0 : parseInt(data.start);
        let datacooldown = isNaN(parseInt(data.cooldown)) ? 4 : parseInt(data.cooldown);
        let datamultiplier = isNaN(parseInt(data.multiplier)) ? 1 : parseInt(data.multiplier);

        let exp = (20 * ((datalevel -1) ** 2) + 35) - datastart;
        let roleMulti = data.roles.map(x => x.exp).reduce((a, b) => a + b);
        roleMulti = isNaN(roleMulti) ? 0 : roleMulti;
        let pmulti = datamultiplier + roleMulti;
        let messages = Math.ceil(exp / pmulti);
        let time = (messages * datacooldown) * 1000;
        let timeText = this.convertTime(time);
        return {
            exp: exp,
            messages: messages,
            time: timeText,
            realTime: ""
        };
    }

    render() {
        return (
            <Container>
                <TitleHeader>
                    <Title>Chat Levels</Title>
                    {/* <TitleButtonContainer>
                        Change
                    </TitleButtonContainer> */}
                </TitleHeader>
                <SubTitle>The following values are all estimated.</SubTitle>
                <InputForm>
                    <InputContainer>
                        <InputTitle>Level</InputTitle>
                        <Input value={this.state.userInput.level} type="number" onChange={(event) => {
                            if(parseInt(event.target.value) < 1 || parseInt(event.target.value) > 100000){
                                return;
                            };
                            let userInput = this.state.userInput;
                            userInput.level = event.target.value;
                            let timeObj = this.calculateResults(userInput);
                            this.setState({userInput: userInput, results: timeObj});
                        }} />
                    </InputContainer>
                    
                    <InputContainer>
                        <InputTitle>Starting EXP/Level</InputTitle>
                        <Input value={this.state.userInput.start} type="number" onChange={(event) => {
                            if(parseInt(event.target.value) < 0){
                                return;
                            };
                            let userInput = this.state.userInput;
                            userInput.start = event.target.value;
                            let timeObj = this.calculateResults(userInput);
                            this.setState({userInput: userInput, results: timeObj});
                        }}/>
                    </InputContainer>
                    
                    <InputContainer>
                        <InputTitle>Cooldown</InputTitle>
                        <Input value={this.state.userInput.cooldown} type="number" onChange={(event) => {
                            if(parseInt(event.target.value) < 4 || parseInt(event.target.value) > 500){
                                return;
                            };
                            let userInput = this.state.userInput;
                            userInput.cooldown = event.target.value;
                            let timeObj = this.calculateResults(userInput);
                            this.setState({userInput: userInput, results: timeObj});
                        }} />
                    </InputContainer>
                </InputForm>

                <InputForm>
                    <InputContainer>
                        <InputTitle>Multiplier</InputTitle>
                        <RoleMultiContainer>
                            <Input value={this.state.userInput.multiplier} type="number" onChange={(event) => {
                                if(parseInt(event.target.value) > 100 || parseInt(event.target.value) < 1){
                                    return;
                                };
                                let userInput = this.state.userInput;
                                userInput.multiplier = event.target.value;
                                let timeObj = this.calculateResults(userInput);
                                this.setState({userInput: userInput, results: timeObj});
                            }} />
                        </RoleMultiContainer>
                    </InputContainer>
                    
                    <InputContainer>
                        <InputTitle>Role</InputTitle>
                        {this.state.userInput.roles && this.state.userInput.roles.map((role, i) => {
                            if(!this.state.userInput.roles[(i + 1)]){
                                return (
                                    <RoleContainer key={i}>
                                        <RoleName style={{color: role.color}}>Role {(i + 1)}</RoleName>
                                        <RoleInput value={role.exp} type="number" onChange={(event) => {
                                            if(parseInt(event.target.value) > 100 || parseInt(event.target.value) < 0){
                                                return;
                                            };
                                            let userInput = this.state.userInput;
                                            userInput.roles[i].exp = parseInt(event.target.value);
                                            let timeObj = this.calculateResults(userInput);
                                            this.setState({userInput: userInput, results: timeObj});
                                        }} />
                                        <RoleButtonContainer onClick={() => {
                                            let color = Math.floor(Math.random()*16777215).toString(16);
                                            let userInput = this.state.userInput;
                                            userInput.roles.push({exp: 0, color: `#${color}`});
                                            let timeObj = this.calculateResults(userInput);
                                            this.setState({userInput: userInput, results: timeObj});
                                        }}>
                                            <RoleButton><FaPlus /></RoleButton>
                                        </RoleButtonContainer>
                                    </RoleContainer>
                                );
                            };

                            return (
                                <RoleContainer key={i}>
                                    <RoleName style={{color: role.color}}>Role {(i + 1)}</RoleName>
                                    <RoleInput value={role.exp} type="number" onChange={(event) => {
                                        if(parseInt(event.target.value) > 100 || parseInt(event.target.value) < 0){
                                            return;
                                        };
                                        let userInput = this.state.userInput;
                                        userInput.roles[i].exp = parseInt(event.target.value);
                                        let timeObj = this.calculateResults(userInput);
                                        this.setState({userInput: userInput, results: timeObj});
                                    }} />
                                    <RoleButtonContainer onClick={() => {
                                        let userInput = this.state.userInput;
                                        userInput.roles.splice(i, 1);
                                        let timeObj = this.calculateResults(userInput);
                                        this.setState({userInput: userInput, results: timeObj});
                                    }}>
                                        <RoleButton><FaMinus /></RoleButton>
                                    </RoleButtonContainer>
                                </RoleContainer>
                            );
                        })}
                    </InputContainer>
                    
                    <InputContainer>
                        <InputTitle>Results</InputTitle>
                        <RequiredContainer>
                            <RequiredTitle>Messages: <RequiredExp>{this.state.results.messages.toLocaleString()}</RequiredExp></RequiredTitle>
                        </RequiredContainer>
                        <RequiredContainer>
                            <RequiredTitle>Required XP: <RequiredExp>{this.state.results.exp.toLocaleString()}</RequiredExp></RequiredTitle>
                        </RequiredContainer>
                        <RequiredContainer>
                            <RequiredTitle>Time: <Required>{this.state.results.time}</Required></RequiredTitle>
                        </RequiredContainer>
                    </InputContainer>
                </InputForm>

            </Container>
        )
    }
};


const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.secondary};
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 12px 15px -15px rgb(0 0 0 / 80%);
    @media screen and (max-width: 768px) {
        padding: 10px;
    }

`;

const TitleHeader = styled.div`
    display: flex;
`;

const Title = styled.div`
    flex: 1;
    font-size: 24px;
    color: ${(props) => props.theme.colors.font};
    font-weight: bold;
`;

const TitleButtonContainer = styled.div`
    font-size: 24px;
    color: ${(props) => props.theme.colors.font};
    font-weight: bold;
`;

const SubTitle = styled.div`
    color: ${(props) => props.theme.colors.secondaryFont};
    font-size: 15px;
    padding: 5px 0 3px 0;
`;

const InputForm = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const InputContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const InputTitle = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 4px 0;
    display: flex;
`;

const Input = styled.input`
    flex: 1;
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    margin-right: 20px;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const RoleContainer = styled.div`
    display: flex;
    margin: 0 0 5px 0;
`;

const RoleName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    font-weight: bold;
    padding-right: 10px;
    font-size: 14px;
`;

const RoleButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 23px 0 10px;
    color: ${(props) => props.theme.colors.font};
`;

const RoleButton = styled.div`
    font-size: 18px;
    background-color: ${(props) => props.theme.colors.highlight}cc;
    font-weight: bold;
    border-radius: 100%;
    padding: 8px 8px 4px 8px;

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const RoleInput = styled.input`
    flex: 1;
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const RoleMultiContainer = styled.div`
    display: flex;
`;

const RequiredContainer = styled.div`
    display: flex;
    margin-top: 10px;
    min-width: 250px;
`;

const RequiredTitle = styled.div`
    font-weight: bold;
    color: ${(props) => props.theme.colors.font};
`;

const RequiredExp = styled.a`
    font-weight: 500;
    word-break: break-all;
`;

const Required = styled.a`
    font-weight: 500;
`;