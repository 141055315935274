// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components

// Import css
import './../../Assets/CSS/Hero.css';

// Import images
import Flowers from './../../Assets/Icons/Flowers.png';
import FlowersAnimated from './../../Assets/Icons/FlowersAnimated.gif';
import K100 from './../../Assets/Icons/100k.png';
import Hype from './../../Assets/Icons/Hype.png';
import Logo from './../../Assets/Icons/Logo.png';
import Love from './../../Assets/Icons/Love.png';
import Tada from './../../Assets/Icons/Tada.png';
import Vote from './../../Assets/Icons/Vote.png';

let logos = [
    Flowers,
    FlowersAnimated,
    K100,
    Hype,
    Logo,
    Love,
    Tada,
    Vote
];

export class Hero extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {logo: 0};
    }

    render() {
        return (
            <Container>
                <img src={logos[this.state.logo]} alt="AmariBot Logo" className="amari-logo" onClick={() =>{
                    let index = this.state.logo + 1;
                    if(!logos[index]){
                        index = 0;
                    };
                    this.setState({logo: index});
                }} />
                <HeroTitle>THE SIMPLE DISCORD <HeroTitleColor>LEVEL SYSTEM</HeroTitleColor> BOT</HeroTitle>
                <HeroSubTitle>NO BLOAT, JUST LEVELS</HeroSubTitle>
                <ButtonContainer>
                    <PrimaryButton href="https://discord.com/oauth2/authorize?client_id=339254240012664832&scope=bot&permissions=517846068288" target="_blank">
                        Add to Discord
                    </PrimaryButton>
                    <SecondaryButton href="https://discord.gg/kqefESMzQj" target="_blank">
                        Support Server
                    </SecondaryButton>
                </ButtonContainer>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.colors.font};
`;

const HeroTitle = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => props.theme.typography["5xl"].fontSize};
    background-color: ${(props) => props.theme.colors.primary};
    text-align: center;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media (max-width: 768px) {
        font-size: ${(props) => props.theme.typography["4xl"].fontSize};
    }

`;

const HeroTitleColor = styled.span`
    color: ${(props) => props.theme.colors.highlight};
`;

const HeroSubTitle = styled.div`
    font-size: 1.5em;
    color: ${(props) => props.theme.colors.secondaryFont};
`;

const ButtonContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30vh;
`;

const PrimaryButton = styled.a`
    display: inline-block;
    padding: 16px 32px;
    margin: 10px 15px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.highlight};;
    color: white;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    &:hover{
        background-color: #fc6a3e;
        cursor: pointer;
    }
`;

const SecondaryButton = styled.a`
    display: inline-block;
    padding: 16px 32px;
    margin: 10px 15px;
    border-radius: 6px;
    background-color: #585a5c;
    color: white;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    &:hover{
        background-color: #4a4c4f;
        cursor: pointer;
    }
`