// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Loading } from '../Loading.jsx';
import { Contributors } from './Contributor.jsx';
import { Developers } from './Developers.jsx';
import { StaffItems } from './Staff.jsx';
import GoogleAdSense from './../../Utils/GoogleAdsense';

// {!https://discord.com/emojis/123123124121312.png} - Emote (Inside the description) !
// <@Woow this user was mentioned> - Mention user
// [#Cool code block] - Code block text

export class Staff extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            staff: {
                developers: [],
                staff: [],
                contributors: []
            }
        };
    }

    async componentDidMount(){
        let staffJson = await fetch(`/json/staff`);
        staffJson = await staffJson.json();
        this.setState({isLoading: false, staff: staffJson.list});
    }


    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>

                    <Content>
                        <Developers developers={this.state.staff.filter(x => x.user_type === "Developers")} />
                        <StaffItems staff={this.state.staff.filter(x => x.user_type === "Staff")}/>
                        <Contributors contributors={this.state.staff.filter(x => x.user_type === "Contributors")}/>
                    </Content>
                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>
                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const AdContent = styled.div`
    max-width: 1920px;
    margin: 0px 10%;
    width: 80%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;