// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import images
import UserFriendlyVideo from './../../Assets/Images/rlevel.gif';

export class UserFriendly extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {logo: 0};
    }

    render() {
        return (
            <Container>
                <UserFriendlyImageContainer>
                    <UserFriendlyImage src={UserFriendlyVideo} alt="" />
                </UserFriendlyImageContainer>
                <UserFriendlyTextContainer>
                    <UserFriendlyTitle>
                        User Friendly
                    </UserFriendlyTitle>
                    <UserFriendlyDescription>
                    Amari's commands are created to be user friendly and easy to understand. Check out our <UserFriendlyHover href="/commands">commands page</UserFriendlyHover> to browse through all the commands.
                    </UserFriendlyDescription>
                </UserFriendlyTextContainer>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    margin: 80px 5%;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: ${(props) => props.theme.colors.font};

    @media (max-width: 900px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0;
    }
`;

const UserFriendlyImageContainer = styled.div`
    flex: 1;
    text-align: center;

    @media (max-width: 768px) {
        order: 2;
        margin-bottom: 40px;
    }
`;

const UserFriendlyImage = styled.img`
    width: 92%;
    height: auto;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.7));
    transition: 0.5s;
    border-radius: 12px;
    &:hover{
        transition: 0.5s;
        transform: scale(1.025);
    }
`;

const UserFriendlyHover = styled.a`
    color: ${(props) => props.theme.colors.highlight};
    text-decoration: none;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
`

const UserFriendlyTextContainer = styled.div`
    flex: 1;
    font-family: 'Montserrat';

    @media (max-width: 768px) {
        margin-bottom: 40px;
        width: 92%;
    }
`;

const UserFriendlyTitle = styled.h1`
    color: ${(props) => props.theme.colors.highlight};
`;

const UserFriendlyDescription = styled.div`
    color: ${(props) => props.theme.colors.font};
    font-size: ${(props) => props.theme.typography.l.fontSize};
`