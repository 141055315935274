// Import node_modules
import React from 'react';
import styled from "styled-components";
import GoogleAdSense from './../../Utils/GoogleAdsense';

// Import components
import { Loading } from '../Loading.jsx';

export class Table extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            rows: 100,
            content: [{level: 0, exp: 0, needed: 0}]
        };
    }

    componentDidMount(){
        let xpArr = this.state.content;
        for(let i=0; i < 100; i++){
            let exp = (20 * (i * i) + 35);
            xpArr.push({level: (i+1), exp: (exp - (xpArr[i].needed)), needed: exp});
        }
        this.setState({isLoading: false, content: xpArr});
    }


    render() {
        if(this.state.isLoading === true){
            return (
                <Container>
                    <Content>
                        <Loading />
                    </Content>
                </Container>
            )
        }else{
            return (
                <Container>

                    <Content>
                        <TableTitle>
                            Level Table
                        </TableTitle>
                        <TableContainer>
                            <TableBlock>
                                <thead>
                                    <RowTitle>
                                        <RowTextLevel>Level</RowTextLevel>
                                        <RowTextExp>Level up Experience</RowTextExp>
                                        <RowTextNeeded>Total Experience</RowTextNeeded>
                                    </RowTitle>
                                </thead>
                                <tbody>
                                    {this.state.content && this.state.content.map((item, i) => {
                                        if((i%100) === 0 && i !== 0){
                                            return (
                                                <>
                                                    <RowContainer key={i}>
                                                        <RowContentLevel>{item.level}</RowContentLevel>
                                                        <RowContentExp>{item.exp}</RowContentExp>
                                                        <RowContentNeeded>{item.needed}</RowContentNeeded>
                                                    </RowContainer>
                                                    <AdContent>
                                                        <GoogleAdSense />
                                                    </AdContent>
                                                </>
                                            )
                                        }else{
                                            return (
                                                <RowContainer key={i}>
                                                    <RowContentLevel>{item.level}</RowContentLevel>
                                                    <RowContentExp>{item.exp}</RowContentExp>
                                                    <RowContentNeeded>{item.needed}</RowContentNeeded>
                                                </RowContainer>
                                            )
                                        }
                                    })}
                                </tbody>
                            </TableBlock>
                        </TableContainer>
                        <LoadMore>
                            <LoadMoreButton onClick={() =>{
                                let newVlaue = this.state.rows + 100;
                                let xpArr = this.state.content;
                                for(let i=this.state.rows; i < newVlaue; i++){
                                    let exp = (20 * (i * i) + 35);
                                    xpArr.push({level: (i+1), exp: (exp - (xpArr[i].needed)), needed: exp});
                                }
                                this.setState({rows: newVlaue})
                            }}>
                                Load more...
                            </LoadMoreButton>
                        </LoadMore>
                    </Content>

                    <AdContent>
                        <GoogleAdSense />
                    </AdContent>

                </Container>
            )
        };
    }
};


const Container = styled.div`
    top: 0;
    bottom: 0;
    max-width: 1920px;
    background-color: ${(props) => props.theme.colors.primary};
`;

const Content = styled.div`
    margin: 0px 10%;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const AdContent = styled.div`
    margin: 20px 10%;
    width: 80%;
    height: auto;
    text-align: center;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const TableContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const TableBlock = styled.table`
    display: flex;
    flex-direction: column;
    width: 70%;

    @media screen and (max-width: 1550px) {
        width: 80%;
    }

    @media screen and (max-width: 1100px) {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

`;

const RowTitle = styled.tr`
    display: flex;
    flex: 1;
    text-align: left;
    padding: 15px 0;
    background-color: ${(props) => props.theme.colors.secondary};
`;

const RowContainer = styled.tr`
    display: flex;
    flex: 1;
    text-align: left;
    padding: 15px 0;
    &:nth-child(even){background-color: ${(props) => props.theme.colors.secondary}};
    &:nth-child(odd){background-color: #21252b8b};
`;

const RowTextLevel = styled.th`
    color: ${(props) => props.theme.colors.font};
    padding: 0 0 0 20px;
    text-decoration: underline;
    min-width: 150px;

    @media (max-width: 800px) {
        min-width: 100px;
    }
    @media (max-width: 800px) {
        min-width: 80px;
    }
`;
const RowTextExp = styled.th`
    color: ${(props) => props.theme.colors.font};
    flex: 1;
    padding: 0 0 0 20px;
    text-decoration: underline;
`;
const RowTextNeeded = styled.th`
    color: ${(props) => props.theme.colors.font};
    flex: 1;
    padding: 0 0 0 20px;
    text-decoration: underline;
`;

const RowContentLevel = styled.td`
    color: ${(props) => props.theme.colors.font};
    padding: 0 0 0 20px;
    min-width: 150px;

    @media (max-width: 800px) {
        min-width: 100px;
    }
    
    @media (max-width: 800px) {
        min-width: 80px;
    }
`;
const RowContentExp = styled.td`
    color: ${(props) => props.theme.colors.font};
    flex: 1;
    padding: 0 0 0 20px;
`;
const RowContentNeeded = styled.td`
    color: ${(props) => props.theme.colors.font};
    flex: 1;
    padding: 0 0 0 20px;
`;

const TableTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
`;

const LoadMore = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadMoreButton = styled.div`
    text-align: center;
    padding: 20px 0;
    background-color: ${(props) => props.theme.colors.highlight}ea;
    border-radius: 0 0 12px 12px;
    color: ${(props) => props.theme.colors.font};
    font-weight: bold;
    width: 70%;

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }

    @media screen and (max-width: 1550px) {
        width: 80%;
    }

    @media screen and (max-width: 1100px) {
        width: 90%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;