// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import components
import { Options } from './Options';
import { Login } from './Login';
import { Mobile } from './Mobile';
import { Avatar } from './Avatar';

export class Navbar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            hideNav: false,
        };
    }


    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
    }
    
    resize() {
        this.setState({hideNav: window.innerWidth <= 835});
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }


    render() {
        if(this.state.hideNav === false){
            return (
                <Container>
                    <Options />
                    {this.props.user.isLogged === true ? <Avatar user={this.props.user} /> : <Login />}
                </Container>
            )
        }else{
            return (
                <Container>
                    <Mobile user={this.props.user} />
                </Container>
            )
        }
    }
};


const Container = styled.div`
    display: flex;
    box-shadow: 0 8px 15px -15px rgb(0 0 0 / 80%);
    background-color: ${(props) => props.theme.colors.primary};
    //border-bottom: 2px solid ${(props) => props.theme.colors.highlight};
    width: 100vw;
    position: relative;
`;