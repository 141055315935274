// Import node_modules
import React from 'react';
import styled from "styled-components";
import AvatarIcon from 'react-avatar';
import Switch from "react-switch";

// Import icons
import { FaMinus, FaPlus } from 'react-icons/fa';

export class StaffInput extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return (
            <Container>
                <InputContainer>
                    <Title>Name</Title>
                    <Input value={this.props.user.name} onChange={this.props.handleName} />
                </InputContainer>
                <InputContainer>
                    <Title>Content</Title>
                    <ContentInput value={this.props.user.content} onChange={this.props.handleContent}/>
                </InputContainer>
                <InputContainer>
                    <Title>Avatar</Title>
                    <Input value={this.props.user.avatar} onChange={this.props.handleAvatar}/>
                    <UpdateButton onClick={this.props.handleAvatarURL}>Update avatar</UpdateButton>
                </InputContainer>
                <InputContainerScroll>
                    <ScrollTitle>
                        Scroll
                    </ScrollTitle>
                    <Switch 
                        onChange={this.props.handleScroll}
                        checked={this.props.user.scroll}
                        onColor="#f79476"
                        onHandleColor="#f67852"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                        
                    />
                </InputContainerScroll>
                {(this.props.user.emotes && this.props.user.emotes.length > 0)? this.props.user.emotes.map((emote, key) => {
                    if(this.props.user.emotes[(key + 1)] || this.props.user.emotes[(key + 1)] === ""){
                        return (
                            <RoleContainer key={key}>
                                <RoleName>Emote {key + 1}</RoleName>
                                <RoleInput value={emote} placeholder="Emote URL" onChange={(event) => {
                                    let value = event.target.value;
                                    this.props.handleEmotesModify(key, value);
                                }} />
                                <RoleButtonContainer onClick={() => {
                                    this.props.handleEmotesRemove(key);
                                }}>
                                    <RoleButton><FaMinus /></RoleButton>
                                </RoleButtonContainer>
                            </RoleContainer>
                        );
                    }else{
                        return (
                            <RoleContainer key={key}>
                                <RoleName>Emote {key + 1}</RoleName>
                                <RoleInput value={emote} placeholder="Emote URL" onChange={(event) => {
                                    let value = event.target.value;
                                    this.props.handleEmotesModify(key, value);
                                }} />
                                <RoleButtonContainer onClick={this.props.handleEmotesAdd}>
                                    <RoleButton><FaPlus /></RoleButton>
                                </RoleButtonContainer>
                            </RoleContainer>
                        );
                    }
                }) : (
                    <RoleContainer>
                        <RoleName>Emote 1</RoleName>
                        <RoleInput value="" placeholder="Emote URL" onChange={(event) => {
                            let value = event.target.value;
                            this.props.handleEmotesModify(0, value);
                        }} />
                        <RoleButtonContainer onClick={this.props.handleEmotesAdd}>
                            <RoleButton><FaPlus /></RoleButton>
                        </RoleButtonContainer>
                    </RoleContainer>
                )}
            </Container>
        )
    }
};


const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding: 0 0 5px 6px;
    color: ${(props) => props.theme.colors.font};
`;

const InputContainer = styled.div`
    padding: 5px 0;
`;

const InputContainerScroll = styled.div`
    display: flex;
    margin: 15px 0 0 0;
`;

const ScrollTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding: 0 0 5px 6px;
    color: ${(props) => props.theme.colors.font};
    flex: 1;
`;

const ContentInput = styled.textarea`
    width: 100%;
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    margin-right: 10px;
    font-family: 'Montserrat';
    font-weight: 500;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const Input = styled.input`
    width: 100%;
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    margin-right: 10px;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const RoleContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 15px;
`;

const RoleName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    font-weight: bold;
    padding: 0 10px 0 6px;
    font-size: 14px;
`;

const RoleButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 10px;
    color: ${(props) => props.theme.colors.font};
`;

const RoleButton = styled.div`
    font-size: 18px;
    background-color: ${(props) => props.theme.colors.highlight}cc;
    font-weight: bold;
    border-radius: 100%;
    padding: 8px 8px 4px 8px;

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const RoleInput = styled.input`
    flex: 1;
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const UpdateButton = styled.div`
    display: inline-block;
    padding: 12px 24px;
    margin: 15px 3px 0 3px;
    border-radius: 6px;
    background-color: ${(props) => props.theme.colors.highlight};;
    color: white;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    text-decoration: none;
    &:hover{
        background-color: #fc6a3e;
        cursor: pointer;
    }
`;

/* 
    name
    image
    content
    emotes
    scroll
*/