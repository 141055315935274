// Import node_modules
import React from 'react';
import styled from "styled-components";
import AvatarIcon from 'react-avatar';

// Import class
import './Contact.css';

export class Coowner extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            donator: "",
            message: "",
            messageSending: false,
            messageError: false,
            messageSent: false,
            spam: false,
            errorUser: false,
            guilds: [],
            activeGuild: {
                name: "Select Guild"
            }
        };
    }

    async componentDidMount(){
        if(!this.props.user.isLogged){
            return window.location.href = "/login?redirect=/application/coowner";
        };
        let query = await fetch(`/json/fetch/coowner/${this.props.user.id}`);
        if(query.status === 200){
            return this.setState({spam: true});
        };
        
        let guilds = await fetch('/json/guilds');
        if(guilds.status === 200){
            guilds = await guilds.json();
            let availableGuilds = guilds.guilds.filter(x => x.admin || x.owner).sort();
            this.setState({ guilds: availableGuilds });
        };
    };

    sendEmail(){
        fetch('/json/coowner', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({user: this.props.user.id, donator: this.state.donator, guild: this.state.activeGuild, message: this.state.message})
        }).then(res => {
            setTimeout(() => {
                if(res.status === 202){
                    return this.setState({errorUser: true, messageError: true, messageSending: false});
                }else if(res.status !== 200){
                    return this.setState({messageError: true, messageSending: false, errorUser: false});
                }
                this.setState({messageSent: true, messageSending: false, messageError: false, activeGuild: { name: "Select Guild" }, donator: "", message: ""});
            }, 1000)
        }).catch((err) => {
            return this.setState({messageError: true, messageSending: false});
        });
    };

    render() {
        let formbtn = this.state.messageSending === true ? (<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>) : this.state.messageError === true ? "Error! Try again" : "Submit";

        if(this.state.spam === true){
            return (
                <Container>
                    <Content>
                        <NoticeBlock>
                            <Title style={{textAlign: 'center'}}>Co-owner Application</Title>
                            <NoticeInfo>You have already applied for Co-owner application. Please wait for that application to be approved before applying for another application. </NoticeInfo>
                        </NoticeBlock>
                    </Content>
                </Container>
            );
        };

        return (
            <Container>
                <Title>Co-owner Application</Title>
                <Content>
                    <FormContainer>
                        <InputTitle>What is the link to the server? (make sure link is permanent)</InputTitle>
                        <DropdownContent>
                            <DropdownMenu>
                                <DropdownTitle>{this.state.activeGuild.name}</DropdownTitle>
                                <DropdownOptions>
                                    {this.state.guilds && this.state.guilds.map((item, index) => (
                                        <DropdownItem key={index} onClick={() => this.setState({activeGuild: item})}>
                                            <AvatarIcon size={44} name={item.name} src={item.icon} round={true} style={{marginRight: '10px'}} /> {item.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownOptions>
                            </DropdownMenu>
                        </DropdownContent>
                        <InputTitle>Please enter the user id for who you want to be Co-Owner?</InputTitle>
                        <EmailInput type="number" name="donator_id" placeholder="Donator ID" value={this.state.donator} onChange={(event) => this.setState({donator: event.target.value})} />
                        {this.state.errorUser === true ? (<ErrorMessage>Unable to find user ID.</ErrorMessage>) : null}
                        <InputTitle>Why does this person need co owner access?</InputTitle>
                        <TextInput name="reason" rows="10" placeholder="Reason" value={this.state.message} onChange={(event) => this.setState({message: event.target.value})} />
                        {this.state.messageSent === false ? (<GreenButton onClick={() => {
                            if(this.state.messageSent) return;
                            if(!this.state.activeGuild.id || this.state.donator.replace(/ /g, "").length < 1 || this.state.message.replace(/ /g, "").length < 1) return;
                            this.sendEmail();
                            this.setState({messageSending: true})
                        }}>{formbtn}</GreenButton>) : (<PurpleButton>Speak soon!</PurpleButton>)}
                    </FormContainer>
                </Content>
            </Container>
        )
    }
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.colors.font};
    font-family: "Montserrat";
    border-radius: 16px;
    @media (max-width: 768px) {
        background-color: ${(props) => props.theme.colors.primary};
        box-shadow: none;
    }
`;

const Content = styled.div`
    max-width: 1920px;
    margin: 0px 12%;
    width: 76%;
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1550px) {
        margin: 0 6%;
        width: 88%;
    }

    @media screen and (max-width: 1100px) {
        margin: 0 3%;
        width: 94%;
    }

    @media (max-width: 768px) {
        margin: 0px 2%;
        width: 96%;
    }
`;

const Title = styled.h1`
    margin: 40px 0 10px 0;
`;

const ErrorMessage = styled.div`
    color: #f31515;
    width: 100%;
    max-width: 750px;
    text-align: left;
    margin-left: -15px;
    margin-top: -5px;
    margin-bottom: 8px;
`;

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
`;

const UsernameInput = styled.input`
    width: 100%;
    max-width: 750px;
    padding: 10px;
    margin: 10px;
    font-family: 'Montserrat';
    font-size: 16px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    &:focus{
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const EmailInput = styled.input`
    width: 100%;
    max-width: 750px;
    padding: 10px;
    margin: 10px;
    font-family: 'Montserrat';
    font-size: 16px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    &:focus{
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;

const TextInput = styled.textarea`
    width: 100%;
    min-width: 250px;
    max-width: 750px;
    min-height: 250px;
    max-height: 250px;
    padding: 10px;
    margin: 10px;
    font-family: 'Montserrat';
    font-size: 16px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;
    &:focus{
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;


const GreenButton = styled.a`
    background-color: ${(props) => props.theme.colors.highlight};
    border: none;
    color: #fff;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 16px;
    width: 150px;
    margin-top: 25px;
    border-radius: 8px;
    font-weight: 600;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    
    &:hover{
        cursor: pointer;
        background-color: #fc6a3e;
    }

`;

const PurpleButton = styled.a`
    background-color: #4a3bce;
    border: none;
    color: #fff;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 16px;
    width: 150px;
    margin-top: 25px;
    border-radius: 8px;
    font-weight: 600;
    font-weight: bold;
    box-shadow: 0 8px 25px -15px rgb(0 0 0 / 80%);
    
    &:hover{
        cursor: no-drop;
        background-color: #5747e7;
    }

`;

const InputTitle = styled.div`
    display: block;
    width: 100%;
    max-width: 750px;
    text-align: left;
    margin-left: -15px;
    margin-top: 15px;
`;

const NoticeBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const NoticeInfo = styled.div`
    margin-top: 20px;
    max-width: 700px;
    height: 70vh;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
`;

const DropdownContent = styled.div`
    display: flex;
    width: 100%;
    max-width: 750px;
    max-height: 20px;
    margin: 15px;
    margin-left: -5px;
`;

const DropdownTitle = styled.div`
    background-color: ${(props) => props.theme.colors.light};
    padding: 10px;
    border-radius: 8px;
`;

const DropdownOptions = styled.div`
    display: none;
    position: absolute;
    background-color: ${(props) => props.theme.colors.secondary};
    min-width: 250px;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
`;

const DropdownMenu = styled.div`
    position: relative;
    display: inline-block;
    min-width: 300px;

    &:hover ${DropdownOptions}{
        display: block;
    }
`;

const DropdownItem = styled.div`
    padding: 10px;

    &:hover{
        background-color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
    }
`;