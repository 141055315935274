// Import node_modules
import React from 'react';
import styled from "styled-components";

export class StaffOutput extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {

        let sentences = this.props.user.content.split(/[<>]|[{}]|[\[\]]+/).filter(x => x.trim());
        let descriptionText = sentences.map((sentence, z) => {
            if(sentence.startsWith("@")){
                return (
                    <UserTag key={z}>
                        {sentence}
                    </UserTag>
                );
            }else if(sentence.startsWith("#")){
                return (
                    <CodeBlock key={z}>
                        {sentence}
                    </CodeBlock>
                );
            }else if(sentence.startsWith("!")){
                let emoteLink = sentence.replace('!', "");
                return (
                    <EmoteBlockContainer key={z}>
                        <EmoteBlock key={z} src={emoteLink} alt="" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.discordapp.com/emojis/710237766159695894.png"}}/>
                    </EmoteBlockContainer>
                );
            }else{
                return (
                    <a key={z}>{sentence}</a>
                );
            }
        });

        return (
            <Container>
                {this.props.user &&
                    (
                        <StaffContainer>
                            <StaffImageContainer>
                                <StaffImage src={this.props.user.avatar} alt="" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.discordapp.com/emojis/710237766159695894.png"}}/>
                            </StaffImageContainer>
                            <StaffName>{this.props.user.name}</StaffName>
                            <StaffDescription>
                                {this.props.user.scroll ? (
                                    <StaffDescriptionTextAnimated>
                                        {descriptionText}
                                    </StaffDescriptionTextAnimated>
                                ) : (
                                    <StaffDescriptionText>
                                        {descriptionText}
                                    </StaffDescriptionText>
                                )}
                            </StaffDescription>
                            <StaffEmoteContainer>
                                {this.props.user.emotes.length > 0 ? this.props.user.emotes.map((emote, y) => (
                                    <StaffEmote key={y} src={emote} alt="" onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn.discordapp.com/emojis/710237766159695894.png"}} />
                                )) : null}
                            </StaffEmoteContainer>
                        </StaffContainer>
                    )
                }
            </Container>
        )
    }
};


const Container = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;
    color: ${(props) => props.theme.colors.font};
    @media (max-width: 800px) {
        margin-top: 50px;
    }
`;

const StaffContainer = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 300px;
    border-radius: 10px;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.secondary};
    box-shadow: 0 0 11px rgb(0 0 0 / 82%), 0 2px 5px rgb(0 0 0 / 10%);
    transition: 0.2s ease-out;
    margin: 10px 15px 30px 15px;
    height: min-content;

    &:hover{
        margin: 5px 15px 35px 15px;
    }
`;

const StaffImageContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
`;

const StaffImage = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 100%;
`;

const StaffName = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 10px;
    font-size: 20px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.highlight};
`;

const StaffDescription = styled.div`
    text-align: center;
    color: ${(props) => props.theme.colors.font};
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    max-height: 120px;
    overflow: hidden;
    flex-direction: column;
`;

const StaffDescriptionText = styled.div`
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 5px;
`;

const StaffDescriptionTextAnimated = styled.div`
    display: inline-block;
    padding: 0 10px;
    animation: 15s coolAnimation infinite;

    @keyframes coolAnimation {
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(-100%);
        }
    }
`;

const StaffTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.font};
    padding: 15px 0;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const StaffEmoteContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 10px;
`;

const StaffEmote = styled.img`
    width: 34px;
    height: 34px;
    padding: 0 3px;
`;

const UserTag = styled.div`
    font-family: 'Montserrat';
    padding: 0px 5px 2px 3px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.colors.highlight}cc;
    display: inline;
    font-size: 14px;
    font-weight: 600;

    &:hover{
        background-color: ${(props) => props.theme.colors.highlight};
        cursor: pointer;
    }
`;

const CodeBlock = styled.div`
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.highlight};
    padding: 0 3px;
    font-family: 'Montserrat';
    display: inline-block;

    &:hover{
        background-color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
    }
`;

const EmoteBlockContainer = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const EmoteBlock = styled.img`
    height: 24px;
    width: 24px;
`;