import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// onApprove
// onCancel
// onError

export default function App(props) {
    let description = props.payment.gift === true ? `${props.user.id} has purchased donator perks for ${props.payment.id}` : `${props.user.id} has purchased donator perks`;
    let userID = props.payment.gift === true ? `${props.payment.id}` : `${props.user.id}`;
    let itemName = props.payment.amount < 5 ? "AmariBot donation" : "AmariBot premium perks";
    return (
        <PayPalScriptProvider options={{ 
            "client-id": "AW2KA7B8VUL67Ucpf2p_kwADNAomxYfdGtzO_AR81sFX_iSSj5V7nEedC4Iy9Pq0zY2xE8mmX1MgFp2Q",
            currency: "USD",
            intent: "capture"
        }}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: props.payment.amount,
                                    breakdown: { item_total: { value: props.payment.amount, currency_code: "USD" } },
                                    currency_code: "USD"
                                },
                                description: `${description}`,
                                custom_id: userID,
                                items: [
                                    {
                                        name: itemName,
                                        unit_amount: { value: props.payment.amount, currency_code: "USD" },
                                        quantity: 1,
                                        category: "DIGITAL_GOODS"
                                    }
                                ],
                                payee: {}
                            },
                        ],
                        application_context: {
                            "brand_name": "AmariBot",
                            "shipping_preference": "NO_SHIPPING",
                            "user_action": "PAY_NOW"
                        }
                    });
                }}

                onApprove={(data, actions) => {
                    return actions.order.capture().then(response => {
                        props.parentObject.setState({approved: true});
                    })
                }}
            />
        </PayPalScriptProvider>
    )
}
