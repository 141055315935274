// Import node_modules
import React from 'react';
import styled from "styled-components";

// Import Components
import { commandsList } from './CommandsList.js';

export class Search extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {search: ""};
    }


    render() {
        return (
            <Container>
                <SearchBar placeholder="Search for command 🔍" value={this.state.search} onChange={(event) => {
                            let search = event.target.value.toLowerCase();
                            let newCommands = commandsList.filter(x => {
                            if(x.name.toLowerCase().includes(search) || x.description.toLowerCase().includes(search) || x.category.toLowerCase().includes(search) ){
                                return x;
                            };
                            let infoContent = x.content.map(y => `${y.example}\n${y.usage}`);
                            if(infoContent.includes(search)){
                                return x;
                            }
                        });

                        this.props.parentObject.setState({commands: newCommands });
                        this.setState({search: event.target.value});
                    }}
                    title="Type in a command name"
                />
            </Container>
        )
    }
};


const Container = styled.div`
    width: 100%;
    display: flex;
    margin-top: 30px;
`;

const SearchBar = styled.input`
    flex: 1;
    transition: 500ms;
    padding: 10px;
    font-size: 18px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.light};
    color: ${(props) => props.theme.colors.font};
    border-radius: 8px;

    &:focus{
        transition: 500ms;
        outline: none;
        border: 2px solid ${(props) => props.theme.colors.highlight};
    }
`;